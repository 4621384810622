import React from 'react';
import { useGameObject } from '../../store/GameContext';
import { Button } from './Playing';
import { track, updateAnalyticsData } from '@magicyard/shared/src/localAnalytics';

export const Tutorial = () => {
  const { G, moves, playerID } = useGameObject();

  return (
    <div
      style={{
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
      }}
    >
      <div style={{ color: 'black', fontSize: 32, position: 'absolute', top: '10%' }}>
        {G.players.length < 3 ? 'Select game mode' : 'Click to start!'}
      </div>
      {G.players.length <= 2 && (
        <Button
          onClick={() => {
            updateAnalyticsData({ mode: '2 Players' });
            track('Game mode chosen');
            moves.endTutorial(2);
          }}
          disabled={false}
          playerID={playerID}
        >
          2 players {G.players.length === 1 ? '\n(With 1 Bot)' : ''}
        </Button>
      )}
      <Button
        onClick={() => {
          updateAnalyticsData({ mode: '4 Players' });
          track('Game mode chosen');
          moves.endTutorial(4);
        }}
        disabled={false}
        playerID={playerID}
      >
        4 players{' '}
        {G.players.length === 4 ? '' : `\n(With ${4 - G.players.length} Bot${4 - G.players.length === 1 ? '' : 's'})`}
      </Button>
    </div>
  );
};
