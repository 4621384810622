import React, { useEffect } from 'react';
import { track } from '@magicyard/shared/src/localAnalytics';
import { Button } from './Playing';
import { useGameObject } from '../../store/GameContext';
import { getWinner } from '@magicyard/magicblocks-game/src/Game';

export const GameOver = ({ onStartNewGame }: { onStartNewGame: () => void }) => {
  const { playerID, G } = useGameObject();

  useEffect(() => {
    const winner = getWinner(G);
    track('Game end phase loaded', {
      winner: winner.playerId,
      isBot: G.players[+winner.playerId].isBot,
    });
    track('Game end phase loaded', { winner: winner.playerId, isBot: G.players[+winner.playerId].isBot });
  }, []);
  return (
    <div style={{ width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      <Button onClick={onStartNewGame} disabled={false} playerID={playerID}>
        Play again!
      </Button>
    </div>
  );
};
