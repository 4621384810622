import {
  Cell,
  CurrentlyHoveringPiece,
  PiecesForPlayer,
  SHAPE_NAME_TO_SHAPE,
  SHAPE_NAMES,
  ShapeName,
  valueToColor,
  valueToHighlightColor,
} from '@magicyard/magicblocks-game/src/Types';
import React from 'react';
import styles from './Pieces.module.css';
import { CSSProperties } from 'styled-components';

const sizes = { lg: 18, sm: 10 };

export const Pieces = ({
  onSelectPiece,
  piecesForPlayer,
  hoveringPiece,
  playerId,
  size,
  activePlayerId,
}: {
  onSelectPiece: (p: ShapeName, i: number) => void;
  piecesForPlayer: PiecesForPlayer;
  playerId: string;
  hoveringPiece: CurrentlyHoveringPiece | null;
  size: 'sm' | 'lg';
  activePlayerId: string;
}) => {
  return (
    <div className={`${styles.piecesContainer} ${styles['piecesContainer-' + size]}`}>
      {SHAPE_NAMES.map((name) => {
        const indexInHand = piecesForPlayer[playerId].findIndex((p) => p === name);
        const isSelected = hoveringPiece !== null && hoveringPiece.shape === name && activePlayerId === playerId;
        return (
          <div
            key={name + playerId}
            className={`${styles.piece} ${styles['piece-' + size]}`}
            style={{
              position: 'relative',
              backgroundColor: isSelected ? valueToHighlightColor[playerId] : undefined,
            }}
            onClick={indexInHand === -1 ? undefined : () => onSelectPiece(name, indexInHand)}
          >
            {SHAPE_NAME_TO_SHAPE[name].map((col, i) => {
              return (
                <div style={{ display: 'flex' }} key={i.toString() + playerId}>
                  {col.reduce<JSX.Element[]>((acc, row, j) => {
                    if (SHAPE_NAME_TO_SHAPE[name][i][j] === 0) {
                      return acc;
                    }
                    acc.push(
                      <div
                        key={i.toString() + j.toString()}
                        className={`${styles.block} ${styles['block-' + size]}`}
                        style={{
                          background: indexInHand === -1 ? '#737272' : valueToColor[playerId],
                          left: i * sizes[size] + SHAPE_NAME_TO_OFFSET_FOR_SIZE[size][name].left * sizes[size],
                          top: j * sizes[size] + SHAPE_NAME_TO_OFFSET_FOR_SIZE[size][name].top * sizes[size],
                          position: 'absolute',
                        }}
                      />
                    );

                    return acc;
                  }, [])}
                </div>
              );
            })}
          </div>
        );
      })}
    </div>
  );
};

// Prolly there's some math that can solve this something like:
// complete the shape to a 5x5 square, and place it in the center of the 5x5 square
const SHAPE_NAME_TO_OFFSET_FOR_SIZE: Record<'lg' | 'sm', Record<ShapeName, { left: number; top: number }>> = {
  lg: {
    '.': { left: 2.25, top: 2.25 },
    '..': { left: 2.25, top: 1.75 },
    ',': { left: 1.75, top: 0.75 },
    '...': { left: 1.25, top: 1.25 },
    '....': { left: 1.25, top: 0.75 },
    j: { left: 1.75, top: 1.25 },
    O: { left: 1.75, top: 1.75 },
    z: { left: 1.75, top: 1.25 },
    t: { left: 1.5, top: 1.25 },
    J: { left: 1, top: 0.75 },
    Z: { left: 1.25, top: 1.25 },
    T: { left: 1.25, top: 1.25 },
    '+': { left: 1.25, top: 1.25 },
    '.....': { left: 0.25, top: 0.25 },
    d: { left: 1.25, top: 0.75 },
    W: { left: 1.25, top: 1.25 },
    C: { left: 1.25, top: 1.75 },
    V: { left: 1.25, top: 1.25 },
    '~': { left: 0.75, top: 0.75 },
    '/': { left: 1.25, top: 1.25 },
    '_.': { left: 0.75, top: 0.75 },
  },
  sm: {
    '.': { left: 2.25, top: 2.25 },
    '..': { left: 2.25, top: 1.75 },
    ',': { left: 1.75, top: 0.75 },
    '...': { left: 1.25, top: 1.25 },
    '....': { left: 1.25, top: 0.75 },
    j: { left: 1.75, top: 1.25 },
    O: { left: 1.75, top: 1.75 },
    z: { left: 1.75, top: 1.25 },
    t: { left: 1.5, top: 1.25 },
    J: { left: 1, top: 0.75 },
    Z: { left: 1.25, top: 1.25 },
    T: { left: 1.25, top: 1.25 },
    '+': { left: 1.25, top: 1.25 },
    '.....': { left: 0.25, top: 0.25 },
    d: { left: 1.25, top: 0.75 },
    W: { left: 1.25, top: 1.25 },
    C: { left: 1.25, top: 1.75 },
    V: { left: 1.25, top: 1.25 },
    '~': { left: 0.75, top: 0.75 },
    '/': { left: 1.25, top: 1.25 },
    '_.': { left: 0.75, top: 0.75 },
  },
};
