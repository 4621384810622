import { INVALID_MOVE } from 'boardgame.io/core';
import {
  Cell,
  CtxWithApi,
  GameState,
  IPlayer,
  SHAPE_NAME_TO_SHAPE,
  SHAPE_NAME_TO_HAS_MIRROR,
  ShapeName,
  ShapeStructure,
} from '../Types';
import { assertIsDefined, assertNever } from './typeUtils';
import { PlayerID } from 'boardgame.io';
import {
  EMPTY_CELL,
  getFourCorners,
  getGridSize,
  getInitialBlockGameState,
  getIsSmall,
  getTwoPlayersStartingPoints,
  Phases,
  playerIdToCellValue,
} from '../Game';

const endSync = (G: GameState, ctx: CtxWithApi) => {
  console.log('ENDING SYNC PHASE');
  console.log(ctx.playerID);
  // Temp, the screen has the same playerId as the first player
  if (ctx.playerID === '0') {
    ctx.events.endPhase();
    return undefined;
  }
  return INVALID_MOVE;
};

// Simultaneous guessing.
const forceEndPhase = (G: GameState, ctx: CtxWithApi) => {
  console.log(`Phase ${ctx.phase} was forced to end`);
  if (!isMoveMadeByMasterClient(ctx)) {
    return INVALID_MOVE;
  }
  const phase = ctx.phase as Phases;
  switch (phase) {
    case Phases.Sync:
      ctx.events.endPhase();
      break;
    case Phases.Tutorial:
    case Phases.Playing:
    case Phases.GameEnd:
      // Last phase, do nothing
      break;
    default:
      assertNever(phase);
  }
  return undefined;
};

const isMoveMadeByMasterClient = (ctx: CtxWithApi) => isIdMasterClient(ctx.playerID, ctx.numPlayers);
const isIdMasterClient = (id: string, numPlayers: number) => +id === numPlayers - 1;

const timesUp = {
  move: (G: GameState, ctx: CtxWithApi) => {
    console.log(`Player ${ctx.playerID} called timesUp on ${ctx.phase}`);

    if (!isMoveMadeByMasterClient(ctx)) {
      return INVALID_MOVE;
    }
    const phase = ctx.phase as Phases;
    switch (phase) {
      case Phases.Sync:
        ctx.events.endPhase();
        break;
      case Phases.Tutorial:
      case Phases.Playing:
      case Phases.GameEnd:
        break;
      default:
        assertNever(phase);
    }

    return undefined;
  },
  ignoreStaleStateID: true,
};

const transitionTimeUp = (G: GameState, ctx: CtxWithApi) => {
  console.log(`Player ${ctx.playerID} ended the transiton for ${ctx.phase}`);
  if (!isMoveMadeByMasterClient(ctx)) {
    return INVALID_MOVE;
  }
  G.isTransition = false;
  return undefined;
};

export const isShapeOverlappingWithOtherPieces = (gameGrid: number[][], cells: Cell[]) => {
  return cells.some((cell) => isCellOverlappingWithOtherCells(gameGrid, cell));
};

const isCellOverlappingWithOtherCells = (gameGrid: number[][], cell: Cell) => gameGrid[cell.x][cell.y] !== EMPTY_CELL;

export const isShapeTouchingEdgeOfOtherPieces = (
  gridSize: number,
  gameGrid: number[][],
  cells: Cell[],
  cellValue: number
) => cells.some((cell) => getCellEdgeTouchInfo(gridSize, cell, gameGrid, cellValue).isTouching);

export const getCellEdgeTouchInfo = (gridSize: number, cell: Cell, gameGrid: number[][], cellValue: number) => {
  const gs = gridSize - 1;
  const isTouchingFromLeft = cell.x > 0 && gameGrid[cell.x - 1][cell.y] === cellValue;
  const isTouchingFromRight = cell.x < gs && gameGrid[cell.x + 1][cell.y] === cellValue;
  const isTouchingFromAbove = cell.y > 0 && gameGrid[cell.x][cell.y - 1] === cellValue;
  const isTouchingFromBelow = cell.y < gs && gameGrid[cell.x][cell.y + 1] === cellValue;
  return {
    isTouchingFromLeft,
    isTouchingFromRight,
    isTouchingFromBelow,
    isTouchingFromAbove,
    isTouching: isTouchingFromLeft || isTouchingFromRight || isTouchingFromBelow || isTouchingFromAbove,
  };
};

export const getShapeTouchingEdgesOfOtherShapes = (
  gridSize: number,
  gameGrid: number[][],
  cells: Cell[],
  cellValue: number,
  allCorners: Cell[]
) =>
  isShapeOverlappingWithOtherPieces(gameGrid, cells) ||
  !allCorners.some((cell) => cells.some((c) => c.x === cell.x && cell.y === c.y))
    ? []
    : cells.reduce<
        {
          cell: Cell;
          info: ReturnType<typeof getCellEdgeTouchInfo>;
        }[]
      >((acc, cell) => {
        const info = getCellEdgeTouchInfo(gridSize, cell, gameGrid, cellValue);
        if (info.isTouching) {
          acc.push({ cell: cell, info: info });
        }

        return acc;
      }, []);

export const getAllCellsForPlayer = (gameGrid: number[][], cellValue: number): Cell[] => {
  const cells: Cell[] = [];
  for (let i = 0; i < gameGrid.length; i++) {
    for (let j = 0; j < gameGrid[i].length; j++) {
      if (gameGrid[i][j] === cellValue) {
        cells.push({ x: i, y: j });
      }
    }
  }
  return cells;
};

export const getAllAvailableCornersForPlayer = (
  players: IPlayer[],
  gameGrid: number[][],
  cellValue: number
): Cell[] => {
  const gridSize = getGridSize(players);
  if (isFirstPiece(gameGrid, cellValue)) {
    if (getIsSmall(players)) {
      return getTwoPlayersStartingPoints(gridSize, gameGrid);
    } else {
      return [getFourCorners(getGridSize(players))[cellValue]];
    }
  }
  const allCells = getAllCellsForPlayer(gameGrid, cellValue);
  return [
    ...new Set(allCells.map((cell) => getAllAvailableCornersForCell(gridSize, gameGrid, cellValue, cell)).flat()),
  ];
};

const getAllAvailableCornersForCell = (
  gridSize: number,
  gameGrid: number[][],
  cellValue: number,
  cell: Cell
): Cell[] => {
  const corners = [
    { x: cell.x - 1, y: cell.y - 1 },
    { x: cell.x - 1, y: cell.y + 1 },
    { x: cell.x + 1, y: cell.y - 1 },
    { x: cell.x + 1, y: cell.y + 1 },
  ];

  return trimOutOfBoundCells(gridSize, corners).filter(
    (cell) =>
      !isShapeTouchingEdgeOfOtherPieces(gridSize, gameGrid, [cell], cellValue) &&
      gameGrid[cell.x][cell.y] === EMPTY_CELL
  );
};

const trimOutOfBoundCells = (gridSize: number, cells: Cell[]) => cells.filter((cell) => isCellInGrid(gridSize, cell));

const isFullyInGrid = (gridSize: number, cells: Cell[]) => cells.every((c) => isCellInGrid(gridSize, c));

const isCellInGrid = (gridSize: number, c: Cell) => c.x < gridSize && c.x >= 0 && c.y < gridSize && c.y >= 0;

export const isTouchingCornerOfOtherPieces = (
  gridSize: number,
  gameGrid: number[][],
  cells: Cell[],
  cellValue: number
) =>
  cells.some((cell) => {
    const { isTouchFromBottomLeft, isTouchingFromTopLeft, isTouchingFromBottomRight, isTouchingFromTopRight } =
      getCellCornerTouchInfo(gridSize, cell, gameGrid, cellValue);

    return isTouchFromBottomLeft || isTouchingFromTopLeft || isTouchingFromBottomRight || isTouchingFromTopRight;
  });

export const getCellCornerTouchInfo = (gridSize: number, cell: Cell, gameGrid: number[][], cellValue: number) => {
  const gs = gridSize - 1;
  const isTouchFromBottomLeft = cell.x !== 0 && cell.y !== 0 && gameGrid[cell.x - 1][cell.y - 1] === cellValue;
  const isTouchingFromTopLeft = cell.x > 0 && cell.y < gs && gameGrid[cell.x - 1][cell.y + 1] === cellValue;
  const isTouchingFromBottomRight = cell.x < gs && cell.y > 0 && gameGrid[cell.x + 1][cell.y - 1] === cellValue;
  const isTouchingFromTopRight = cell.x < gs && cell.y < gs && gameGrid[cell.x + 1][cell.y + 1] === cellValue;
  return {
    isTouchFromBottomLeft,
    isTouchingFromTopLeft,
    isTouchingFromBottomRight,
    isTouchingFromTopRight,
  };
};

export const canPlaceNotFirstPiece = (G: GameState, playerID: PlayerID, cells: Cell[]) => {
  const gridSize = getGridSize(G.players);
  const trimmed = trimOutOfBoundCells(gridSize, cells);
  return (
    trimmed.length === cells.length &&
    !isShapeOverlappingWithOtherPieces(G.gameGrid, cells) &&
    !isShapeTouchingEdgeOfOtherPieces(getGridSize(G.players), G.gameGrid, cells, playerIdToCellValue(playerID)) &&
    isTouchingCornerOfOtherPieces(gridSize, G.gameGrid, cells, playerIdToCellValue(playerID))
  );
};

export const isFirstPiece = (grid: number[][], cellValue: number) => {
  return getAllCellsForPlayer(grid, cellValue).length === 0;
};

export const canPlaceFirstPiece = (players: IPlayer[], grid: number[][], cells: Cell[], cellValue: number) => {
  const gridSize = getGridSize(players);
  if (getIsSmall(players)) {
    const startingPoints = getTwoPlayersStartingPoints(gridSize, grid);
    const isTouching2PlayerStartingPoints = cells.some((cell) =>
      startingPoints.some((sCell) => cell.x === sCell.x && cell.y === sCell.y)
    );
    return isTouching2PlayerStartingPoints;
  } else {
    const corner = getFourCorners(gridSize)[cellValue];
    const isTouchingTheCorner = cells.some((cell) => cell.x === corner.x && cell.y === corner.y);

    return isTouchingTheCorner;
  }
};

export const canPlaceAnyPiece = (G: GameState, playerID: PlayerID, cells: Cell[]) => {
  if (isFirstPiece(G.gameGrid, playerIdToCellValue(playerID))) {
    if (!canPlaceFirstPiece(G.players, G.gameGrid, cells, playerIdToCellValue(playerID))) {
      return false;
    }
  } else if (!canPlaceNotFirstPiece(G, playerID, cells)) {
    return false;
  }

  return true;
};
export const isActivePlayer = (G: GameState, ctx: CtxWithApi, playerId: PlayerID) =>
  G.activePlayer === playerId || isIdMasterClient(playerId, ctx.numPlayers);

const skipTurn = (G: GameState, ctx: CtxWithApi) => {
  G.playersThatSkipped.push(G.activePlayer);
  endTurn(G, ctx);
};

const placePiece = (G: GameState, ctx: CtxWithApi, pieceIndex: number, cells: Cell[]) => {
  if (!isActivePlayer(G, ctx, ctx.playerID)) {
    return INVALID_MOVE;
  }

  if (!canPlaceAnyPiece(G, ctx.playerID, cells)) {
    console.log('CANT PLACE', cells);
    return INVALID_MOVE;
  }

  cells.forEach((cell) => {
    G.gameGrid[cell.x][cell.y] = playerIdToCellValue(G.activePlayer);
  });
  const shapeName = G.piecesForPlayer[G.activePlayer][pieceIndex];
  G.piecesForPlayer[G.activePlayer].splice(pieceIndex, 1);
  G.lastPlacedPiece = { cells: cells, id: shapeName + ' ' + G.activePlayer, placedBy: G.activePlayer };
  ctx.effects.placePiece(G.activePlayer);
  endTurn(G, ctx);
  return undefined;
};

const endTurn = (G: GameState, ctx: CtxWithApi) => {
  G.currentlyHoveringPiece = null;

  if (G.playersThatSkipped.length === G.players.length) {
    ctx.events.endPhase();
  } else {
    let nextPlayer = (+G.activePlayer + 1) % G.players.length;
    while (G.playersThatSkipped.includes('' + nextPlayer)) {
      nextPlayer = (nextPlayer + 1) % G.players.length;
    }
    G.activePlayer = '' + nextPlayer;
  }
};

const movePiece = (
  G: GameState,
  ctx: CtxWithApi,
  direction: {
    x: number;
    y: number;
  },
  rotation?: number,
  toggleMirror?: boolean
) => {
  if (!isActivePlayer(G, ctx, ctx.playerID)) {
    return INVALID_MOVE;
  }
  if (G.currentlyHoveringPiece === null) {
    console.log('Invalid cause did not select');
    return INVALID_MOVE;
  }
  if (direction.x !== 0 || direction.y !== 0) {
    G.currentlyHoveringPiece.centerLocation = {
      x: G.currentlyHoveringPiece.centerLocation.x + direction.x,
      y: G.currentlyHoveringPiece.centerLocation.y + direction.y,
    };
    ctx.effects.movePiece(direction);
  }

  if (rotation !== undefined && rotation !== null && rotation !== G.currentlyHoveringPiece.rotation) {
    G.currentlyHoveringPiece.rotation = rotation;
    ctx.effects.rotatePiece();
  }

  if (toggleMirror === true) {
    G.currentlyHoveringPiece.mirrored = !G.currentlyHoveringPiece.mirrored;
    ctx.effects.mirrorPiece();
  }

  snapShapeInToGrid(G);

  return undefined;
};

export const snapShapeInToGrid = (G: GameState) => {
  assertIsDefined(G.currentlyHoveringPiece);

  const cells = cellsToHoverOver(
    G.currentlyHoveringPiece.shape,
    G.currentlyHoveringPiece.rotation,
    G.currentlyHoveringPiece.centerLocation,
    G.currentlyHoveringPiece.mirrored
  );

  const extremes = cells.reduce(
    (acc, cell) => {
      acc.minX = Math.min(acc.minX, cell.x);
      acc.maxX = Math.max(acc.maxX, cell.x);
      acc.minY = Math.min(acc.minY, cell.y);
      acc.maxY = Math.max(acc.maxY, cell.y);

      return acc;
    },
    { minX: 0, maxX: 0, minY: 0, maxY: 0 }
  );
  const gridSize = getGridSize(G.players);
  if (extremes.maxX >= gridSize) {
    G.currentlyHoveringPiece.centerLocation.x -= extremes.maxX - gridSize + 1;
  } else if (extremes.minX < 0) {
    G.currentlyHoveringPiece.centerLocation.x += Math.abs(extremes.minX);
  }
  if (extremes.maxY >= gridSize) {
    G.currentlyHoveringPiece.centerLocation.y -= extremes.maxY - gridSize + 1;
  } else if (extremes.minY < 0) {
    G.currentlyHoveringPiece.centerLocation.y += Math.abs(extremes.minY);
  }
};

const selectPiece = (G: GameState, ctx: CtxWithApi, shapeName: ShapeName, indexInHand: number) => {
  if (!isActivePlayer(G, ctx, ctx.playerID)) {
    return INVALID_MOVE;
  }

  if (G.currentlyHoveringPiece === null) {
    G.currentlyHoveringPiece = {
      indexInHand: indexInHand,
      shape: shapeName,
      rotation: 0,
      centerLocation: { x: getGridSize(G.players) / 2, y: getGridSize(G.players) / 2 },
      mirrored: false,
    };
  } else {
    G.currentlyHoveringPiece.shape = shapeName;
    G.currentlyHoveringPiece.rotation = 0;
    G.currentlyHoveringPiece.indexInHand = indexInHand;
    G.currentlyHoveringPiece.mirrored = false;
    snapShapeInToGrid(G);
  }
  ctx.effects.selectPiece(G.activePlayer);

  return undefined;
};

const rotate90Degrees = (shape: ShapeStructure): ShapeStructure => {
  const rotatedShape: ShapeStructure = [];
  const numRows = shape.length;
  const numCols = shape[0].length;
  for (let col = 0; col < numCols; col++) {
    const newRow: number[] = [];
    for (let row = numRows - 1; row >= 0; row--) {
      newRow.push(shape[row][col]);
    }
    rotatedShape.push(newRow);
  }
  return rotatedShape;
};
export const rotateShape = (shape: ShapeStructure, count: number): ShapeStructure => {
  let rotated = shape;
  for (let i = 0; i < count % 4; i++) {
    rotated = rotate90Degrees(rotated);
  }

  return rotated;
};

function mirrorShape(shape: ShapeStructure) {
  const numRows = shape.length;
  const mirroredMatrix: number[][] = [];

  for (let i = 0; i < numRows; i++) {
    mirroredMatrix.push(shape[i].slice().reverse());
  }

  return mirroredMatrix;
}

const endTutorial = (G: GameState, ctx: CtxWithApi, gameMode: 2 | 4) => {
  const completeTo = G.players.length === 3 || G.players.length === 4 ? 4 : gameMode;
  const players = [...G.players];
  while (players.length < completeTo) {
    players.push({
      avatarUrl: ['', '', ''][players.length - 1],
      controller_id: String(players.length),
      id: String(players.length),
      isBot: true,
      name: ['Blocky McBlockface', 'MasterBlocker', 'SquareBear'][players.length - 1],
    });
  }

  ctx.events.endPhase();

  return { ...G, ...getInitialBlockGameState(players), players: players };
};
export const movesUtil = {
  endSync,
  timesUp,
  forceEndPhase,
  transitionTimeUp,
  placePiece,
  movePiece,
  selectPiece,
  skipTurn,
  endTutorial,
};

export const getCurrentCellsToHoverOver = (G: GameState): Cell[] => {
  if (G.currentlyHoveringPiece === null) {
    return [];
  }
  return cellsToHoverOver(
    G.currentlyHoveringPiece.shape,
    G.currentlyHoveringPiece.rotation,
    G.currentlyHoveringPiece.centerLocation,
    G.currentlyHoveringPiece.mirrored
  );
};

export const cellsToHoverOver = (
  shapeName: ShapeName,
  rotation: number,
  centerLocation: Cell,
  mirror: boolean
): Cell[] => {
  const shape = rotateShape(SHAPE_NAME_TO_SHAPE[shapeName], rotation);
  const mirrored = mirror ? mirrorShape(shape) : shape;
  const cells = [];
  for (let i = 0; i < mirrored.length; i++) {
    for (let j = 0; j < mirrored[i].length; j++) {
      if (mirrored[i][j] !== 0) {
        cells.push({
          x: i + centerLocation.x,
          y: j + centerLocation.y,
        });
      }
    }
  }

  return cells;
};

export const mustPlayerSkipTurn = (G: GameState) => {
  const corners = getAllAvailableCornersForPlayer(G.players, G.gameGrid, playerIdToCellValue(G.activePlayer));
  const pieces = G.piecesForPlayer[G.activePlayer];

  return !corners.some((corner) => pieces.some((shapeName) => canPlaceShapeAtPosition(G, shapeName, corner) !== null));
};

const canPlaceShapeAtPosition = (G: GameState, shapeName: ShapeName, corner: Cell) => {
  const shape = SHAPE_NAME_TO_SHAPE[shapeName];
  for (let i = 0; i < shape.length; i++) {
    for (let j = 0; j < shape[i].length; j++) {
      const currPosAbove = { x: corner.x + i, y: corner.y + j };
      const currPosBelow = { x: corner.x - i, y: corner.y - j };
      for (let r = 0; r < 4; r++) {
        const cellsAbove = cellsToHoverOver(shapeName, r, currPosAbove, false);
        if (isFullyInGrid(getGridSize(G.players), cellsAbove) && canPlaceAnyPiece(G, G.activePlayer, cellsAbove)) {
          return { pos: currPosAbove, rotation: r, mirror: false };
        }
        const cellsBelow = cellsToHoverOver(shapeName, r, currPosBelow, false);
        if (isFullyInGrid(getGridSize(G.players), cellsBelow) && canPlaceAnyPiece(G, G.activePlayer, cellsBelow)) {
          return { pos: currPosBelow, rotation: r, mirror: false };
        }

        if (SHAPE_NAME_TO_HAS_MIRROR[shapeName]) {
          const cellsAboveMirrored = cellsToHoverOver(shapeName, r, currPosAbove, true);
          if (
            isFullyInGrid(getGridSize(G.players), cellsAboveMirrored) &&
            canPlaceAnyPiece(G, G.activePlayer, cellsAboveMirrored)
          ) {
            return { pos: currPosAbove, rotation: r, mirror: true };
          }
          const cellsBelowMirrored = cellsToHoverOver(shapeName, r, currPosBelow, true);
          if (
            isFullyInGrid(getGridSize(G.players), cellsBelowMirrored) &&
            canPlaceAnyPiece(G, G.activePlayer, cellsBelowMirrored)
          ) {
            return { pos: currPosBelow, rotation: r, mirror: true };
          }
        }
      }
    }
  }
  return null;
};

function calcD(vectorA: Cell, vectorB: Cell): number {
  const xDiff = vectorA.x - vectorB.x;
  const yDiff = vectorA.y - vectorB.y;

  return Math.sqrt(xDiff * xDiff + yDiff * yDiff);
}

export const getAllAvailablePositionForPlayer = (G: GameState) => {
  const gs = (getGridSize(G.players) - 1) / 2;
  const centerPoint = { x: gs, y: gs };
  const corners = getAllAvailableCornersForPlayer(G.players, G.gameGrid, playerIdToCellValue(G.activePlayer)).sort(
    (a, b) => calcD(a, centerPoint) - calcD(b, centerPoint)
  );
  const pieces = G.piecesForPlayer[G.activePlayer];

  const possibleShapes: Record<
    ShapeName,
    {
      position: Cell;
      rotation: number;
      mirror: boolean;
    }
  > = {};
  corners.forEach((corner) =>
    pieces.forEach((shapeName) => {
      if (possibleShapes[shapeName] === undefined) {
        const x = canPlaceShapeAtPosition(G, shapeName, corner);
        if (x !== null) {
          possibleShapes[shapeName] = { position: x.pos, rotation: x.rotation, mirror: x.mirror };
        }
      }
    }, [])
  );

  return possibleShapes;
};
