export function assertNever(value: never, noThrow?: boolean): never {
  if (noThrow) {
    return value;
  }

  throw new Error(`Unhandled discriminated union member: ${JSON.stringify(value)}`);
}

export function assert(condition: unknown, message: string): asserts condition {
  if (!condition) throw new Error(message);
}

export function assertIsDefined<T>(val: T | undefined | null, message?: string): asserts val is T {
  return assert(val !== undefined && val !== null, message ?? 'Expected value to be defined');
}
