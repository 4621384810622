import { io, Socket } from 'socket.io-client';

export function sendToSocket(socket: Socket, event: string, data?: unknown): void {
  socket.emit('action', { event: event, data: data });
}

// TODO debug, not yet possible ot use
export async function sendToSocketAsync<T>(socket: Socket, event: string, data?: unknown): Promise<T> {
  return await socket.emitWithAck('action', { event: event, data: data });
}

export type SocketConnectionData = { type: 'controller' | 'display'; id: string };

/** Returns a promise that resolves with the socket once it is connected */
export function connectSocket(
  url: string,
  socketData: SocketConnectionData,
  handleConnect: () => void,
  onReconnectAttempt: (attempt: number) => void
): Socket {
  const socket = io(url, {
    path: '/ws/socket.io/',
    query: {
      client_type: socketData.type,
    },
    auth: {
      id: socketData.id,
    },
  });

  socket.on('connect', handleConnect);
  socket.io.on('reconnect', handleConnect);

  // We have infinite for now, so shouldnt happen
  socket.io.on('reconnect_failed', () => {
    console.log('reconnect_failed');
  });

  // Will fire with reconnection attempt
  socket.on('connect_error', () => {
    console.log('connect_error');
  });
  // Will fire with reconnection attempt
  socket.io.on('error', (err) => console.log('socket.io.on', err));

  socket.io.on('reconnect_attempt', onReconnectAttempt);

  return socket;
}

export async function connectSocket2(url: string): Promise<Socket> {
  return await new Promise((resolve, reject) => {
    try {
      const socket = io(url);

      const handleConnect = () => {
        socket.off('connect', handleConnect);
        socket.off('connect_error', handleError);
        resolve(socket);
      };
      const handleError = (error: Error) => {
        socket.off('connect', handleConnect);
        socket.off('connect_error', handleError);
        reject(error);
      };
      socket.on('connect', handleConnect);
      socket.on('connect_error', handleError);
    } catch (error) {
      reject(error);
    }
  });
}
