import React, { FC, useCallback, useRef } from 'react';
import { Dialog, DialogContent, IconButton, Slide } from '@material-ui/core';
import Close from '@material-ui/icons/Close';
import Webcam from 'react-webcam';
import './TakePicDialog.css';
import Background from '@magicyard/magicblocks-controller/src/components/Background/Background';
import { track } from '@magicyard/shared/src/localAnalytics';
import { GameButton } from './GameButton/GameButton';

export interface TakePicProps {
  open: boolean;
  onClose: () => void;
  onTakePic: (data: string | null) => void;
}

const videoConstraints = {
  aspectRatio: '1',
  facingMode: 'user',
};

const TakePicDialog: FC<TakePicProps> = ({ open, onTakePic, onClose }) => {
  const webcamRef = useRef<Webcam>(null);
  const [loading, setLoading] = React.useState(false);

  const capture = useCallback(() => {
    if (webcamRef.current !== null) {
      const picData = webcamRef.current.getScreenshot({
        width: 300,
        height: 300,
      });
      setLoading(true);
      fetch('https://image-upload.magicyard.workers.dev/', {
        method: 'POST',
        body: JSON.stringify({ url: picData, filename: `doodledash/seflies/${Date.now()}` }),
        headers: { 'Content-Type': 'application/json' },
      })
        .then(async (resp) => await resp.json())
        .then((uploadResponse) => {
          const url = uploadResponse.result.variants[0];
          onTakePic(url);
          track('Selfie Taken');
        })
        .finally(() => setLoading(false));
    }
  }, [setLoading, webcamRef, onTakePic]);

  const onCaptureClick = () => {
    capture();
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullScreen
      TransitionComponent={Slide}
      TransitionProps={{
        // @ts-expect-error
        direction: 'up',
      }}
    >
      <Background>
        <div className={'take-pic-dialog-root'}>
          <IconButton className={'take-pic-dialog-close-icon-button'} onClick={onClose}>
            <Close />
          </IconButton>
          <DialogContent
            className={'take-pic-dialog-image-wrapper'}
            style={{ display: 'flex', justifyContent: 'space-around', alignItems: 'center' }}
          >
            {loading ? (
              <div style={{ fontSize: 32 }}>Uploading...</div>
            ) : (
              <>
                <Webcam
                  className={'take-pic-dialog-image'}
                  audio={false}
                  ref={webcamRef}
                  forceScreenshotSourceSize
                  mirrored={true}
                  height="300"
                  width="300"
                  screenshotFormat="image/jpeg"
                  videoConstraints={videoConstraints as MediaTrackConstraints}
                />
                <GameButton onClick={onCaptureClick} variant={0}>
                  Capture
                </GameButton>
              </>
            )}
          </DialogContent>
        </div>
      </Background>
    </Dialog>
  );
};

export default TakePicDialog;
