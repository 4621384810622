import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { getExistingController, createController, saveControllerProfile } from '../lib/controller';
import {
  connectToSocket,
  connectToDisplayWithIdSocket,
  ControllerDto,
  joinYard,
  onControllerUpdated,
  onGameStarting,
  onJoinedYard,
  onYardUpdated,
  patchController,
  Profile,
  rehydrate,
  startGame,
  yardDtoToYard,
  getYardAfterMutation,
  onGameStartFailure,
  startOnlineGame,
  onQueueUpdated,
  onJoinedQueue,
  onLeaveQueue,
  leaveQueue,
  connectToDisplayWithCode,
  yardWithQueueToYardWithGame,
  YardDto,
  sendReaction,
  onReactionReceived,
  joinVoiceChat,
  grabVoiceChat,
  updateMuteStateVoiceChat,
  leaveVoiceChat,
  yardToYardDto,
  endGame,
  onGameEndedController,
  onGameStartedController,
  yardDtoToYardWithGameUnsafe,
  generateRandomDefaultName,
  connectToDisplay,
  sendNavigationCommand,
} from '../lib/api';
import { isShortURL, lengthenURL } from '../lib/urlShortener';
import { useVoiceCall, VoiceCall } from './useVoiceCall';
import {
  Communication,
  ConnectionState,
  ControllerState,
  getDisplayIdFromControllerState,
  getGameStartArgsFromControllerState,
  getIsReconnecting,
  getMicState,
  getYardFromControllerState,
  getYardIdFromControllerState,
  InvalidYardState,
  NavigationState,
  navigationStateToControllerState,
  navigationStateToYardState,
  NavigationStateWithController,
  NavigationStateWithYard,
  ProfileResult,
  StateConfig,
  StatesWithController,
  YardAndDisplayState,
  YardState,
} from './usePlatformControllerTypes';
import { assertNever } from '@magicyard/utils/typeUtils';

interface Query {
  displayId: string | null;
  yardId: string | null;
}

type SetNavState = Dispatch<SetStateAction<NavigationState>>;

const getOrCreateController = async (defaultUserImage: string, defaultUserName?: string) => {
  const controller = await getExistingController();
  if (controller === null) {
    return await onSubmitProfile({
      name: defaultUserName ?? generateRandomDefaultName(),
      avatarUrl: defaultUserImage,
    });
  }

  return controller;
};

const getDisplayIdToConnectTo = (initialQuery: Query, controller: ControllerDto) => {
  if (initialQuery.displayId !== null && initialQuery.displayId !== controller.display_id) {
    return initialQuery.displayId;
  }
  return null;
};

const getInitialState = async (
  setNavState: SetNavState,
  initialQuery: Query,
  setConnectionState: (state: ConnectionState) => void,
  defaultUserImage: string,
  defaultUserName?: string
): Promise<void> => {
  const controller = await getOrCreateController(defaultUserImage, defaultUserName);
  const toConnectTo = getDisplayIdToConnectTo(initialQuery, controller);
  if (toConnectTo !== null) {
    try {
      // Mutates the server
      await connectToDisplay(controller.id, toConnectTo);
    } catch (e) {
      console.log('The following error was intentionally ignored:', e);
    }
  }

  connectToSocket({
    socketData: { type: 'controller', id: controller.id },
    handleConnect: async () => {
      setConnectionState('connected');
      // This is done because handleConnect is called every time the socket reconnects
      // and we want the latest state of the controller
      const controller = await getOrCreateController(defaultUserImage, defaultUserName);
      setNavState(controllerDtoToNavigationState(controller));
    },
    handleReconnecting: () => {
      setConnectionState('reconnecting');
    },
  });
};

const getControllerDtoFromNavStateAndUpdates = (
  controllerState: NavigationStateWithController,
  updates: UpdateControllerUpdates
): ControllerDto => {
  const oldController = controllerState.state.controller;
  const oldYard = getYardFromControllerState(controllerState);
  const yard =
    updates.yard === null ? null : updates.yard === undefined ? oldYard : getYardAfterMutation(updates.yard, oldYard);
  return {
    is_online: updates.isOnline ?? oldController.isOnline,
    name: updates.profile?.name ?? oldController.profile.name,
    avatar_url: updates.profile?.avatarUrl ?? oldController.profile.avatarUrl,
    id: oldController.profile.id,
    display_id: updates.displayId ?? getDisplayIdFromControllerState(controllerState),
    yard: yard === null ? null : yardToYardDto(yard),
    game_start_args: updates.gameStartArgs ?? getGameStartArgsFromControllerState(controllerState),
  };
};

interface UpdateControllerUpdates {
  yard?: Partial<YardDto> | null;
  displayId?: string | null;
  gameStartArgs?: unknown;
  profile?: Partial<Profile>;
  isOnline?: boolean;
}

const updateController = (setNavState: SetNavState, updates: UpdateControllerUpdates): void => {
  setNavState((oldNavState) => {
    const oldControllerState = navigationStateToControllerState(oldNavState);
    switch (oldControllerState.type) {
      case 'no_controller': {
        console.log('Trying to update controller before it exists, this may or may not indicate an issue');
        return oldNavState;
      }
      case 'controller': {
        // TODO the server must send a game_url
        const controllerDto: ControllerDto = getControllerDtoFromNavStateAndUpdates(oldControllerState, updates);
        // TODO this update prolly not important
        saveControllerProfile(controllerDto);
        return controllerDtoToNavigationState(controllerDto);
      }
      default: {
        assertNever(oldControllerState);
      }
    }
  });
};

// TODO refactor this to just do listeners, let the caller provide the functions
const useControllerUpdate = (controllerState: ControllerState, setNavState: SetNavState) => {
  useEffect(() => {
    switch (controllerState.type) {
      case 'no_controller':
        // Do nothing..
        return undefined;
      case 'controller': {
        const clearJoined = onJoinedYard((yard) => {
          updateController(setNavState, { yard: yard });
        });
        const clearUpdated = onYardUpdated((updates) => {
          updateController(setNavState, { yard: updates });
        });

        const clearControllerUpdate = onControllerUpdated((controller) => {
          // TODO talk to lior, do we ignore the yard herre?
          updateController(setNavState, {
            displayId: controller.display_id,
            yard: controller.yard,
          });
        });

        const clearGameEndedController = onGameEndedController((controllerDto) => {
          setNavState(controllerDtoToNavigationState(controllerDto));
        });

        const clearGameStarting = onGameStarting(() => {
          setNavState((oldNavState) => {
            const oldControllerState = navigationStateToControllerState(oldNavState);
            switch (oldControllerState.type) {
              case 'no_controller':
                throw new Error(`Impossible to start game when in ${oldControllerState.type}`);
              case 'controller':
                switch (oldControllerState.state.navigation) {
                  case 'loading_game':
                  case 'game':
                  case 'queue':
                    return {
                      navigation: 'loading_game',
                      controller: {
                        isOnline: oldControllerState.state.controller.isOnline,
                        profile: oldControllerState.state.controller.profile,
                        yard: yardWithQueueToYardWithGame(oldControllerState.state.controller.yard),
                        displayId: oldControllerState.state.controller.displayId,
                      },
                    };
                  case 'yard_display': {
                    if (oldControllerState.state.controller.yard.type !== 'withGame') {
                      throw new Error("Temp error because we don't have game selection navigation yet!");
                    }
                    return {
                      navigation: 'loading_game',
                      controller: {
                        isOnline: oldControllerState.state.controller.isOnline,
                        profile: oldControllerState.state.controller.profile,
                        yard: oldControllerState.state.controller.yard,
                        displayId: oldControllerState.state.controller.displayId,
                      },
                    };
                  }
                  case 'yard':
                  case 'invalid_yard':
                    throw new Error(`Impossible to start game when in ${oldControllerState.state.navigation}`);
                  default:
                    assertNever(oldControllerState.state);
                }
                break;
              default:
                assertNever(oldControllerState);
            }
          });
        });

        const clearGameStartFail = onGameStartFailure((e) => {
          // Todo temp
          alert(e.reason);
        });

        const clearJoinedQueue = onJoinedQueue(({ id, yards }) => {
          setNavState((oldNavState) => {
            const oldControllerState = navigationStateToControllerState(oldNavState);
            switch (oldControllerState.type) {
              case 'no_controller':
                throw new Error(`Impossible to start game when in ${oldControllerState.type}`);
              case 'controller':
                switch (oldControllerState.state.navigation) {
                  case 'loading_game':
                  case 'game':
                  case 'queue':
                    return {
                      navigation: 'queue',
                      controller: {
                        profile: oldControllerState.state.controller.profile,
                        isOnline: oldControllerState.state.controller.isOnline,
                        yard: {
                          ...oldControllerState.state.controller.yard,
                          type: 'withQueue',
                          queue: { id: id, yards: yards.map(yardDtoToYard) },
                        },
                        displayId: oldControllerState.state.controller.displayId,
                      },
                    };
                  case 'yard_display': {
                    if (oldControllerState.state.controller.yard.type !== 'withGame') {
                      throw new Error("Temp error because we don't have game selection navigation yet!");
                    }
                    return {
                      navigation: 'queue',
                      controller: {
                        isOnline: oldControllerState.state.controller.isOnline,
                        profile: oldControllerState.state.controller.profile,
                        yard: {
                          ...oldControllerState.state.controller.yard,
                          type: 'withQueue',
                          queue: { id: id, yards: yards.map(yardDtoToYard) },
                        },
                        displayId: oldControllerState.state.controller.displayId,
                      },
                    };
                  }
                  case 'yard':
                  case 'invalid_yard':
                    throw new Error(`Impossible to start game when in ${oldControllerState.state.navigation}`);
                  default:
                    assertNever(oldControllerState.state);
                }
                break;
              default:
                assertNever(oldControllerState);
            }
          });
        });

        const clearQueueUpdated = onQueueUpdated(() => {
          // TODO Update yardWithQueue to have the new data
        });

        const clearLeaveQueue = onLeaveQueue(() => {
          setNavState((oldNavState) => {
            const oldControllerState = navigationStateToControllerState(oldNavState);
            switch (oldControllerState.type) {
              case 'no_controller':
                throw new Error(`Impossible to start game when in ${oldControllerState.type}`);
              case 'controller':
                switch (oldControllerState.state.navigation) {
                  case 'loading_game':
                  case 'game':
                  case 'queue':
                    return {
                      navigation: 'yard_display',
                      controller: {
                        isOnline: oldControllerState.state.controller.isOnline,
                        profile: oldControllerState.state.controller.profile,
                        yard: yardWithQueueToYardWithGame(oldControllerState.state.controller.yard),
                        displayId: oldControllerState.state.controller.displayId,
                      },
                    };
                  case 'yard_display': {
                    return {
                      navigation: 'yard_display',
                      controller: {
                        isOnline: oldControllerState.state.controller.isOnline,
                        profile: oldControllerState.state.controller.profile,
                        yard: oldControllerState.state.controller.yard,
                        displayId: oldControllerState.state.controller.displayId,
                      },
                    };
                  }
                  case 'yard':
                  case 'invalid_yard':
                    throw new Error(`Impossible to start game when in ${oldControllerState.state.navigation}`);
                  default:
                    assertNever(oldControllerState.state);
                }
                break;
              default:
                assertNever(oldControllerState);
            }
          });
        });

        const clearGameStarted = onGameStartedController((controller) => {
          setNavState(controllerDtoToNavigationState(controller));
        });
        return () => {
          clearGameStarting();
          clearGameStarted();
          clearControllerUpdate();
          clearJoined();
          clearUpdated();
          clearGameStartFail();
          clearJoinedQueue();
          clearQueueUpdated();
          clearLeaveQueue();
          clearGameEndedController();
        };
      }
      default:
        return assertNever(controllerState);
    }
  }, [controllerState.state.navigation, setNavState]);
};

/**
 * @typeParam T - the type of the state the callbacks update (by returning it)
 * @param stateConfig - configuration with game event callbacks which update the state
 * @param initialQuery - the initial query parameters
 */
export function usePlatformController<T>(
  stateConfig: StateConfig<T>,
  initialQuery: Query,
  defaultUserImage: string,
  defaultUserName?: string
): T {
  const [navState, setNavState] = useState<NavigationState>({ navigation: 'initial' });
  const [connectionState, setConnectionState] = useState<ConnectionState | null>(null);
  const controllerState = navigationStateToControllerState(navState);

  useControllerUpdate(controllerState, setNavState);

  useEffect(() => {
    void getInitialState(setNavState, initialQuery, setConnectionState, defaultUserImage, defaultUserName);
  }, []);
  const micState = getMicState(navState);
  const voiceCall = useVoiceCall({
    soundDisabled: micState === null || micState.voiceChatState === null || micState.speakerData === undefined,
    muted:
      micState === null ||
      micState.voiceChatState === null ||
      micState.speakerData === undefined ||
      micState.speakerData.muted,
  });

  useJoinVoiceChatWhenYardIsAvailable(setNavState, navState, voiceCall);

  useEffect(() => {
    switch (controllerState.type) {
      case 'no_controller': {
        // Ignore initalLoading, wait for getInitialState to finish
        break;
      }
      case 'controller': {
        const localYardId = getYardIdFromControllerState(controllerState);
        const queryYardId = initialQuery.yardId;
        if (queryYardId !== null && queryYardId !== localYardId) {
          console.debug(`Joining yard ${queryYardId}...`);
          joinYard(queryYardId);
        } else {
          console.debug('Rehydrating yard...');
          rehydrate();
        }
        break;
      }
      default: {
        assertNever(controllerState);
      }
    }
  }, [controllerState.type === 'controller', initialQuery.displayId, initialQuery.yardId]);
  const handleDisplayScan = async (navState: YardState | InvalidYardState, url: string) => {
    // TODO should this have a loading screen?

    if (!isShortURL(url)) {
      // TODO What to do? The user scanned something wrong.. i guess nothing?
      return;
    }
    const longUrl = await lengthenURL(url);
    const search = new URLSearchParams(new URL(longUrl).search);
    const yardState = navigationStateToYardState(navState);
    const displayId = search.get('displayId');
    if (displayId !== null) {
      switch (yardState.type) {
        case 'no_yard':
          console.debug(`Connecting to display ${displayId}...`);
          connectToDisplayWithIdSocket(displayId);
          break;
        case 'yard':
          console.debug(
            `Connecting to display ${displayId} and switching it to a new yard ${yardState.state.controller.yard.id}`
          );
          connectToDisplayWithIdSocket(displayId, true);
          break;
        default:
          assertNever(yardState);
      }
      // TODO should immediatly do this, or is there a loading here...?
      // Otherwise we wait for the socket?
      // setNavState({
      //   navigation: 'yard_display',
      //   controller: { ...navState.controller, displayId: displayId },
      // });
    }
  };

  const handStartGameRequest = (extras?: any) => {
    setNavState((oldNavState) => {
      const controllerState = navigationStateToControllerState(oldNavState);
      switch (controllerState.type) {
        case 'no_controller':
          throw new Error('Expecting a controller before moving to game');
        case 'controller': {
          const yard = getYardFromControllerState(controllerState);
          if (yard === null) {
            throw new Error('Impossible to start a game without a yard');
          } else if (yard.type !== 'withGame') {
            // TODO temp state, until gameSelectionState is handled
            throw new Error('Impossible to start a game with a gameId, (prolly issue with some display)');
          }
          startGame(yard.gameId, extras);
          return {
            ...oldNavState,
            type: 'loading_game',
          };
        }
        default:
          return assertNever(controllerState);
      }
    });
  };

  const handleStartGameOnlineRequest = () => {
    setNavState((oldNavState) => {
      const controllerState = navigationStateToControllerState(oldNavState);
      switch (controllerState.type) {
        case 'no_controller':
          throw new Error('Expecting a controller before moving to game');
        case 'controller': {
          const yard = getYardFromControllerState(controllerState);
          if (yard === null) {
            throw new Error('Impossible to start a game without a yard');
          } else if (yard.type !== 'withGame') {
            // TODO temp state, until gameSelectionState is handled
            throw new Error('Impossible to start a game with a gameId, (prolly issue with some display)');
          }
          startOnlineGame(yard.gameId);
          return {
            ...oldNavState,
            type: 'queue',
          };
        }
        default:
          return assertNever(controllerState);
      }
    });
  };

  switch (navState.navigation) {
    case 'queue':
      return stateConfig.onOnlineQueue({
        onProfileUpdate: async (newProfile) => await onProfileUpdate(setNavState, navState, newProfile),
        isReconnecting: getIsReconnecting(connectionState),
        controller: navState.controller,
        communication: getComms(setNavState, navState, voiceCall),
        onLeaveQueue: () => {
          console.log('LEAVING QUEUE', navState.controller.yard.queue);
          leaveQueue(navState.controller.yard.queue.id);

          const nextState: YardAndDisplayState = {
            navigation: 'yard_display',
            controller: {
              ...navState.controller,
              yard: yardWithQueueToYardWithGame(navState.controller.yard),
            },
          };

          // TODO use old nav state....
          setNavState(nextState);
        },
      });
    case 'initial':
      return stateConfig.onInitialLoading();
    case 'yard':
      return stateConfig.onYard({
        isReconnecting: getIsReconnecting(connectionState),
        controller: navState.controller,
        onDisplayScanned: async (qrCodeUrlResult) => await handleDisplayScan(navState, qrCodeUrlResult),
        onRoomCodeEntered: (code) => handleDisplayCodeEntered(navState, code),
        onProfileUpdate: async (newProfile) => await onProfileUpdate(setNavState, navState, newProfile),
        communication: getComms(setNavState, navState, voiceCall),
      });
    case 'yard_display':
      // TODO for internal use a new state called "game selection", and this state always has a YardWithGame
      return stateConfig.onYardWithDisplay({
        isReconnecting: getIsReconnecting(connectionState),
        controller: navState.controller,
        onProfileUpdate: async (newProfile) => await onProfileUpdate(setNavState, navState, newProfile),
        onSubmitOnline: handleStartGameOnlineRequest,
        onSubmitLocal: handStartGameRequest,
        communication: getComms(setNavState, navState, voiceCall),
      });
    case 'loading_game':
      return stateConfig.onGameLoading({
        onProfileUpdate: async (newProfile) => await onProfileUpdate(setNavState, navState, newProfile),
        isReconnecting: getIsReconnecting(connectionState),
        controller: navState.controller,
        communication: getComms(setNavState, navState, voiceCall),
      });
    case 'game':
      return stateConfig.onGame({
        onProfileUpdate: async (newProfile) => await onProfileUpdate(setNavState, navState, newProfile),
        isReconnecting: getIsReconnecting(connectionState),
        controller: navState.controller,
        onGameEnd: () => endGame(true),
        communication: getComms(setNavState, navState, voiceCall),
        onStartAgain: handStartGameRequest,
        onStartAgainOnline: startOnlineGame,
      });
    case 'invalid_yard':
      return stateConfig.onInvalidYard({
        onProfileUpdate: async (newProfile) => await onProfileUpdate(setNavState, navState, newProfile),
        isReconnecting: getIsReconnecting(connectionState),
        controller: navState.controller,
        onDisplayScanned: async (qrCodeUrlResult) => await handleDisplayScan(navState, qrCodeUrlResult),
        onDisplayCodeEntered: (code) => handleDisplayCodeEntered(navState, code),
      });
    default:
      assertNever(navState);
  }
}

const controllerDtoToNavigationState = (controllerDto: ControllerDto): NavigationState => {
  const profile = { id: controllerDto.id, name: controllerDto.name, avatarUrl: controllerDto.avatar_url };
  if (controllerDto.yard === null) {
    return {
      navigation: 'invalid_yard',
      controller: { profile: profile, isOnline: controllerDto.is_online },
    };
  }

  if (controllerDto.display_id === null) {
    return {
      navigation: 'yard',
      controller: { yard: yardDtoToYard(controllerDto.yard), profile: profile, isOnline: controllerDto.is_online },
    };
  }

  if (controllerDto.yard.game_starting) {
    return {
      navigation: 'loading_game',
      controller: {
        isOnline: controllerDto.is_online,
        displayId: controllerDto.display_id,
        yard: yardDtoToYardWithGameUnsafe(controllerDto.yard),
        profile: profile,
      },
    };
  }
  if (controllerDto.game_start_args !== null && controllerDto.game_start_args !== undefined) {
    return {
      navigation: 'game',
      controller: {
        isOnline: controllerDto.is_online,
        gameStartArgs: controllerDto.game_start_args,
        displayId: controllerDto.display_id,
        yard: yardDtoToYardWithGameUnsafe(controllerDto.yard),
        profile: profile,
      },
    };
  }

  const yard = yardDtoToYard(controllerDto.yard);
  switch (yard.type) {
    case 'basic':
    case 'withGame':
      return {
        navigation: 'yard_display',
        controller: {
          isOnline: controllerDto.is_online,
          displayId: controllerDto.display_id,
          yard: yard,
          profile: profile,
        },
      };
    case 'withQueue':
      return {
        navigation: 'queue',
        controller: {
          isOnline: controllerDto.is_online,
          displayId: controllerDto.display_id,
          yard: yard,
          profile: profile,
        },
      };
    default:
      assertNever(yard);
  }
};

const onProfileUpdate = async (
  setNavState: SetNavState,
  navState: StatesWithController,
  newProfile: Partial<Omit<Profile, 'id'>>
) => {
  updateController(setNavState, { profile: newProfile });
  try {
    await patchController(navState.controller.profile.id, { name: newProfile.name, avatar_url: newProfile.avatarUrl });
  } catch (e) {
    console.log('Could not patch controller');
  }
};

const onSubmitProfile = async (result: ProfileResult): Promise<ControllerDto> => {
  return await createController({ name: result.name, avatar_url: result.avatarUrl });
};

export const getComms = (
  setNavState: SetNavState,
  navState: NavigationStateWithYard['state'],
  voiceCall: VoiceCall
): Communication => {
  const voiceChatState = navState.controller.yard.voiceChatState;
  return {
    voice:
      voiceChatState !== null
        ? {
            micState: {
              join: async () => {
                await voiceCall.joinVoice();
                joinVoiceChat();
                return {
                  setMute: updateMuteStateVoiceChat,
                  grab: grabVoiceChat,
                  leave: async () => {
                    leaveVoiceChat();
                    return await voiceCall.leave();
                  },
                };
              },
              speakers: voiceChatState.speakers,
            },
          }
        : null,
    sendReaction: sendReaction,
    receiveReaction: onReactionReceived,
    sendNavigationCommand: sendNavigationCommand,
  };
};
const useJoinVoiceChatWhenYardIsAvailable = (
  setNavState: SetNavState,
  navState: NavigationState,
  voiceCall: VoiceCall
) => {
  const yardState = navigationStateToYardState(navState);

  useEffect(() => {
    switch (yardState.type) {
      case 'no_yard':
        // Wait for yard
        break;
      case 'yard': {
        if (yardState.state.controller.yard.voiceChatState !== null) {
          switch (voiceCall.connectionState) {
            case 'DISCONNECTED':
            case 'DISCONNECTING':
            case 'RECONNECTING': {
              console.log('joining agora');
              voiceCall.joinAgora(yardState.state.controller.yard.id).catch(console.error);
              break;
            }
            case 'CONNECTING':
            case 'CONNECTED':
              break;
            default:
              assertNever(voiceCall.connectionState);
          }
        } else if (voiceCall.connectionState !== 'DISCONNECTED') {
          console.debug('Leaving the voice call');
          voiceCall.leave().catch(console.error);
        }

        break;
      }
      default:
        assertNever(yardState);
    }
  }, [yardState.type === 'yard' ? yardState.state.controller.yard.voiceChatState === null : null]);
};

const handleDisplayCodeEntered = (navState: NavigationState, code: string) => {
  const yardState = navigationStateToYardState(navState);
  switch (yardState.type) {
    case 'no_yard':
      connectToDisplayWithCode(code);
      break;
    case 'yard':
      connectToDisplayWithCode(code, true);
      break;
    default:
      assertNever(yardState);
  }
};
