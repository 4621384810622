import type { EventEmitter } from 'events';

export function createEventFn<T>(emitter: EventEmitter, event: string): (handler: (data: T) => void) => () => void {
  return (handler) => {
    emitter.on(event, handler);
    return () => {
      emitter.off(event, handler);
    };
  };
}
