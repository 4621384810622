import React, { useEffect, useMemo, useState } from 'react';
import { useGameObject } from '../../store/GameContext';
import {
  canPlaceAnyPiece,
  getCurrentCellsToHoverOver,
  isActivePlayer,
  mustPlayerSkipTurn,
} from '@magicyard/magicblocks-game/src/utils/moves.util';
import styles from './Playing.module.css';
import {
  SHAPE_NAME_TO_HAS_MIRROR,
  valueToColor,
  valueToHighlightColor,
  valueToSecondaryColor,
} from '@magicyard/magicblocks-game/src/Types';
import { Pieces } from '@magicyard/magicblocks-shared/magicblocks/Pieces';
import { PlayerID } from 'boardgame.io';

export const Playing = () => {
  const { G, moves, playerID, ctx } = useGameObject();
  const currentlySelected = G.currentlyHoveringPiece;
  const mustSkipTurn = useMemo(
    () => mustPlayerSkipTurn(G),
    [isActivePlayer(G, ctx, playerID), G.piecesForPlayer[G.activePlayer].length]
  );
  const shouldOpenDrawer = currentlySelected === null && isActivePlayer(G, ctx, playerID) && !mustSkipTurn;
  const [isOpen, setIsOpen] = useState(shouldOpenDrawer);
  useEffect(() => {
    if (shouldOpenDrawer) {
      // When it becomes your turn show the drawer opening
      // TODO a temp hack, remove timeout
      setTimeout(() => {
        setIsOpen(true);
      }, 50);
    }
  }, [shouldOpenDrawer]);

  if (!isActivePlayer(G, ctx, playerID)) {
    return (
      <div
        style={{
          color: valueToColor[playerID],
          fontSize: 40,
          textAlign: 'center',
          width: '100%',
          height: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        {G.playersThatSkipped.includes(playerID)
          ? 'You are out of the game!'
          : `Waiting for ${G.players[+G.activePlayer].name}...`}
      </div>
    );
  }

  const hoveringOver = getCurrentCellsToHoverOver(G);
  const canPlace = canPlaceAnyPiece(G, playerID, hoveringOver);

  return (
    <div className={styles.root} style={{ background: valueToColor[-1] }}>
      {mustSkipTurn ? (
        <>
          <div style={{ color: 'red', fontSize: 30, marginTop: 20 }}>You are out of moves!</div>
          <Button onClick={() => moves.skipTurn()} playerID={playerID} disabled={false}>
            Click & skip your turn
          </Button>
        </>
      ) : (
        <>
          <DPad
            playerID={playerID}
            onClick={(d, r, m) =>
              currentlySelected === null ? undefined : moves.movePiece(d, currentlySelected.rotation + (r ?? 0), m)
            }
            canMirror={currentlySelected === null ? false : SHAPE_NAME_TO_HAS_MIRROR[currentlySelected.shape]}
          />
          {currentlySelected === null && !mustSkipTurn && (
            <div style={{ color: 'red', marginTop: 30, marginBottom: -25, fontWeight: 'bold' }}>
              Select one of the shapes below
            </div>
          )}
          <Drawer isOpen={isOpen} setIsOpen={setIsOpen} playerID={playerID}>
            <Pieces
              activePlayerId={G.activePlayer}
              piecesForPlayer={G.piecesForPlayer}
              hoveringPiece={G.currentlyHoveringPiece}
              playerId={G.activePlayer}
              onSelectPiece={(p, i) => {
                setIsOpen(false);
                moves.selectPiece(p, i);
              }}
              size={'lg'}
            />
          </Drawer>
          {currentlySelected !== null && (
            <Button
              onClick={() => moves.placePiece(currentlySelected!.indexInHand, hoveringOver)}
              disabled={!canPlace}
              playerID={playerID}
            >
              Place
            </Button>
          )}
        </>
      )}
    </div>
  );
};

export const Button = ({
  onClick,
  disabled,
  playerID,
  children,
}: {
  onClick: () => void;
  disabled: boolean;
  playerID: PlayerID;
  children: React.ReactNode;
}) => {
  return (
    <div
      onClick={onClick}
      className={`${styles.button} ${!disabled ? styles.buttonOn : styles.buttonOff}`}
      style={{ background: !disabled ? valueToColor[playerID] : valueToSecondaryColor[playerID] }}
    >
      {children}
    </div>
  );
};

const DPad = ({
  onClick,
  playerID,
  canMirror,
}: {
  playerID: string;
  onClick: (direction: { x: number; y: number }, rotation?: number, mirror?: boolean) => void;
  canMirror: boolean;
}) => {
  return (
    <div className={styles.dPad}>
      <div
        className={`${styles.dPadArrow} ${styles.arrowUp}`}
        style={{ background: valueToHighlightColor[playerID] }}
        onClick={() => onClick({ x: 0, y: -1 })}
      >
        <div className={styles.dPadArrowIcon} style={{ transform: 'rotate(-90deg)' }} />
      </div>
      <div className={styles.leftRight}>
        <div
          className={`${styles.dPadArrow} ${styles.arrowLeft}`}
          style={{ background: valueToHighlightColor[playerID] }}
          onClick={() => onClick({ x: -1, y: 0 })}
        >
          <div className={styles.dPadArrowIcon} style={{ transform: 'rotate(180deg)' }} />
        </div>
        <div
          className={styles.dPadArrow}
          style={{ background: valueToHighlightColor[playerID] }}
          onClick={() => onClick({ x: 0, y: 0 }, 1)}
        >
          <div className={styles.dPadRotateIcon} />
        </div>
        <div
          className={`${styles.dPadArrow} ${styles.arrowRight}`}
          style={{ background: valueToHighlightColor[playerID] }}
          onClick={() => onClick({ x: 1, y: 0 })}
        >
          <div className={styles.dPadArrowIcon} />
        </div>
      </div>
      <div
        className={`${styles.dPadArrow} ${styles.arrowDown}`}
        style={{ background: valueToHighlightColor[playerID] }}
        onClick={() => onClick({ x: 0, y: 1 })}
      >
        <div className={styles.dPadArrowIcon} style={{ transform: 'rotate(90deg)' }} />
      </div>
      {canMirror && (
        <div className={styles.mirrorAnimationContainer}>
          <div
            className={`${styles.dPadArrow} ${styles.mirror}`}
            style={{ background: valueToHighlightColor[playerID] }}
            onClick={() => onClick({ x: 0, y: 0 }, 0, true)}
          >
            <div className={styles.dPadMirrorIcon} />
          </div>
        </div>
      )}
    </div>
  );
};

const Drawer = ({
  children,
  isOpen,
  setIsOpen,
  playerID,
}: {
  children: React.ReactNode;
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  playerID: string;
}) => {
  return (
    <>
      <div className={`${styles.drawerBackground} ${isOpen ? styles.drawerBackgroundOpen : ''}`} />
      <div
        className={`${styles.drawer} ${isOpen ? styles.drawerOpen : styles.drawerClose}`}
        style={{ background: valueToHighlightColor['-1'] }}
      >
        <div
          className={`${styles.drawerArrow} ${styles.arrowUp}`}
          style={{ background: valueToHighlightColor[playerID] }}
          onClick={() => setIsOpen(!isOpen)}
        >
          <div
            className={styles.dPadArrowIcon}
            style={{ transform: `rotate(${isOpen ? 90 : -90}deg)`, transition: '0.3s' }}
          />
        </div>
        <div className={styles.drawerBody}>{children}</div>
      </div>
    </>
  );
};
