import React, { useEffect } from 'react';
import './InQueue.css';
import { YardWithQueueController } from '@magicyard/shared/platform/hooks/usePlatformControllerTypes';

interface InQueueProps {
  onLeaveQueue: () => void;
  controller: YardWithQueueController;
}

export const InQueue = ({ onLeaveQueue }: InQueueProps) => {
  const [queueText, setQueueText] = React.useState('60');

  useEffect(() => {
    const interval = window.setInterval(() => {
      setQueueText((last) => {
        if (last === '1') {
          return `${Math.floor(Math.random() * 60) + 1}`;
        }
        return `${Number.parseInt(last) - 1}`;
      });
    }, 1000);

    return () => {
      window.clearInterval(interval);
    };
  }, []);

  return (
    <div className={'in-queue_root'}>
      <div className={'in-queue_loading-container'}>Finding players, should take about a minute...</div>
      <div className={'in-queue_loading-container'}>{queueText}</div>
      {/*<GameButton onClick={onLeaveQueue} text={'Leave queue'} />*/}
    </div>
  );
};
