import * as api from './api';
import { connectToDisplay } from './api';

const PROFILE_LOCAL_STORAGE_ITEM = 'CONTROLLER';

/** Get stored controller, hydrated with the API */
export async function getExistingController(): Promise<api.ControllerDto | null> {
  const storedProfile = getStoredProfile();

  if (storedProfile === null) {
    return null;
  }

  const liveController = await getOrRecreateLiveController(storedProfile);
  console.debug('Saving controller to local storage...');
  saveControllerProfile(liveController);
  console.debug('Saved controller to local storage');

  return liveController;
}

/** Create a controller and store it locally */
export async function createController(createInput: api.ControllerCreateInput) {
  const controller = await api.createController(createInput);
  saveControllerProfile(controller);
  return controller;
}

/** Saves profile to local storage */
export function saveControllerProfile(controller: api.ControllerDto): void {
  console.debug('Saving controller profile to local storage...');
  const profile = api.controllerDtoToProfile(controller);
  localStorage.setItem(PROFILE_LOCAL_STORAGE_ITEM, JSON.stringify(profile));
  console.debug('Saved controller profile to local storage');
}

/** Get a hydrated version of the given stored controller, or recreate it */
async function getOrRecreateLiveController(storedProfile: api.Profile): Promise<api.ControllerDto> {
  try {
    console.debug('Rehydrating controller from API...');
    const liveController = await api.getController(storedProfile.id);
    console.debug('Rehydrated controller from API', liveController);
    return liveController;
  } catch (error) {
    if (api.isNotFoundError(error as Error)) {
      return await recreateLiveController(storedProfile);
    } else {
      throw error;
    }
  }
}

/**
 * Recreate the stored controller in the backend as it's state not persisted and
 * the client may have a profile already that the backend "forgot".
 */
async function recreateLiveController(storedProfile: api.Profile): Promise<api.ControllerDto> {
  console.debug('Recreating controller...');

  const liveController = await api.createController({
    id: storedProfile.id,
    name: storedProfile.name,
    avatar_url: storedProfile.avatarUrl,
  });
  console.debug('Recreated controller');
  return liveController;
}

/**
 * Get controller from local storage or null if local storage is not available
 * or there is no saved controller
 */
function getStoredProfile(): api.Profile | null {
  console.debug('Fetching controller profile from local storage...');
  if (typeof localStorage === 'undefined') {
    return null;
  }
  const raw = localStorage.getItem(PROFILE_LOCAL_STORAGE_ITEM);
  if (raw === null) {
    return null;
  }
  const profile = JSON.parse(raw);
  console.debug('Fetched controller profile from local storage', profile);
  return profile;
}
