import React from 'react';
import './Background.css';
import { valueToColor } from '@magicyard/magicblocks-game/src/Types';

export type BackgroundProps = {
  className?: string;
  children?: React.ReactNode;
  image?: any;
};

export const Background = ({ children }: BackgroundProps) => {
  return (
    <div className={'background_root'} style={{ background: valueToColor[-1] }}>
      {children}
    </div>
  );
};

export default Background;
