import { useCallback, useEffect, useMemo, useState } from 'react';
import AgoraRTC, {
  ConnectionState,
  createClient as createAgoraClient,
  IAgoraRTCClient,
  IAgoraRTCRemoteUser,
  IMicrophoneAudioTrack,
  UID,
} from 'agora-rtc-react';

export interface VoiceCallOptions {
  /**
   * Should the sound of all the users be disabled by default
   * @default false
   */
  soundDisabled?: boolean;
  /**
   * Should the system's microphone be muted by default
   * @default false
   */
  muted?: boolean;
  /**
   * Agora token, optional
   * @default null
   */
  token?: string | null;
  /** Agora UID, optional */
  uid?: UID | null | undefined;
}

export type VoiceCall = Pick<IAgoraRTCClient, 'connectionState' | 'remoteUsers' | 'uid' | 'channelName'> & {
  /** The Agora RTC Client */
  agoraClient: IAgoraRTCClient;
  /** Whether an action is loading */
  loading: boolean;
  /** An error thrown while executing an action */
  error: Error | undefined;
  /**
   * Join the call
   * @returns Agora UID
   */
  joinAgora: (channel: string) => Promise<UID>;
  joinVoice: () => Promise<void>;
  /** Leave the call */
  leave: () => Promise<void>;
};

type MediaType = 'audio' | 'video';

export const useAgoraClient = createAgoraClient({
  mode: 'rtc',
  codec: 'vp8',
});

// TODO change this to env?
const NEXT_PUBLIC_AGORA_APP_ID = 'e7fc6d77d52943f2ae6dd716a306e5cb';

if (NEXT_PUBLIC_AGORA_APP_ID === undefined) {
  throw new Error('NEXT_PUBLIC_AGORA_APP_ID must be defined');
}

const AGORA_APP_ID = NEXT_PUBLIC_AGORA_APP_ID;

/** `
 * Use a voice call.
 * To start a voice call call the join() method. To stop, call leave().
 * Powered by Agora.
 * Design choices:
 * Remote users are always subscribed to. Their sound is heard by toggling their audio track.
 * The are unsubscribed when leaving the call.
 * Microphone is created on join and published immediately. Its sound is heard by toggling its audio track volume.
 * The remote users array is updated every time a user joins, leaves, publishes, un-publishes, subscribed to, and subscribed from.
 * This is done to prioritize fast mute, unmute, sound enable-disable over optimized usage of inputs and accurate UX indication (browser microphone and sound indicators)
 */
export function useVoiceCall({
  soundDisabled = false,
  muted = false,
  token = null,
  uid: initialUID,
}: VoiceCallOptions = {}): VoiceCall {
  const agoraClient = useAgoraClient();

  // Loading states
  const [joining, setJoining] = useState(false);
  const [leaving, setLeaving] = useState(false);

  // Tracked microphone audio track
  const [microphoneAudioTrack, setMicrophoneAudioTrack] = useState<IMicrophoneAudioTrack>();

  // Tracked clones of agoraClient members
  const [connectionState, setConnectionState] = useState<ConnectionState>(agoraClient.connectionState);
  const [remoteUsers, setRemoteUsers] = useState<IAgoraRTCRemoteUser[]>([]);
  const [uid, setUID] = useState<UID | undefined>(agoraClient.uid);
  const [channelName, setChannelName] = useState(agoraClient.channelName);

  // Tracked general error
  const [error, setError] = useState<Error>();

  const updateRemoteUsers = useCallback(() => {
    setRemoteUsers([...agoraClient.remoteUsers]);
    console.debug('[useVoiceCall] Updated remote users');
  }, [agoraClient]);

  const handleUserPublished = useCallback(
    (user: IAgoraRTCRemoteUser, mediaType: MediaType) => {
      if (mediaType !== 'audio') {
        return;
      }
      // Update the remote users list once the user is published
      updateRemoteUsers();
      // Automatically listen to everyone who joins
      agoraClient
        .subscribe(user, mediaType)
        .then(() => {
          console.debug('[useVoiceCall] Subscribed to user');
          // Update the remote users list once the user is subscribed
          // The user.audioTrack should be changed to defined
          updateRemoteUsers();
        })
        .catch(setError);
    },
    [agoraClient, updateRemoteUsers]
  );

  const handleUserUnpublished = useCallback(
    (user: IAgoraRTCRemoteUser, mediaType: MediaType) => {
      if (mediaType !== 'audio') {
        return;
      }
      // Update the remote users list once the user is unpublished
      updateRemoteUsers();
      agoraClient
        .unsubscribe(user, mediaType)
        .then(() => {
          console.debug('[useVoiceCall] Unsubscribed to user');
          // Update the remote users list once the user is unsubscribed
          // The user.audioTrack should be changed to undefined
          updateRemoteUsers();
        })
        .catch(setError);
    },
    [agoraClient, updateRemoteUsers]
  );

  const joinAgora = useCallback(
    async (channel: string) => {
      setJoining(true);

      try {
        const receivedUID = await agoraClient.join(AGORA_APP_ID, channel, token, initialUID);
        setUID(receivedUID);
        setChannelName(channel);
        return receivedUID;
      } catch (error) {
        setError(error as Error);
        console.error(error);
        throw error;
      } finally {
        setJoining(false);
      }
    },
    [setJoining, agoraClient, token, initialUID]
  );

  const joinVoice = useCallback(async () => {
    setJoining(true);
    try {
      const microphoneAudioTrack = await AgoraRTC.createMicrophoneAudioTrack();
      // await microphoneAudioTrack.setPlaybackDevice('speakerphone')

      console.debug('[useVoiceCall] create microphone track');

      // Initialize microphone as muted
      microphoneAudioTrack.setVolume(0);
      console.debug('[useVoiceCall] muted microphone track');

      // Publish the microphone to Agora
      await agoraClient.publish(microphoneAudioTrack);
      console.debug('[useVoiceCall] published microphone track');

      setMicrophoneAudioTrack(microphoneAudioTrack);
    } catch (e) {
      setError(e as Error);
      console.error(e);
    } finally {
      setJoining(false);
    }
  }, [agoraClient]);

  const leave = useCallback(async () => {
    setLeaving(true);
    setMicrophoneAudioTrack(undefined);
    try {
      // Close and un-publish all the published track (in our case only the microphone track)
      await Promise.all(
        agoraClient.localTracks.map(async (track) => {
          track.stop();
          track.close();
          await agoraClient.unpublish([track]);
        })
      );
    } catch (error) {
      setError(error as Error);
      throw error;
    } finally {
      setLeaving(false);
    }
  }, [agoraClient, setError]);

  useEffect(() => {
    agoraClient.on('connection-state-change', setConnectionState);

    // Update the remote users list when a user joins
    // A new user should be added to the array
    agoraClient.on('user-joined', updateRemoteUsers);

    // Update the remote users list once a user has left
    // A new user should be removed from the array
    agoraClient.on('user-left', updateRemoteUsers);

    agoraClient.on('user-published', handleUserPublished);
    agoraClient.on('user-unpublished', handleUserUnpublished);

    return () => {
      agoraClient.off('connection-state-change', setConnectionState);
      agoraClient.off('user-joined', updateRemoteUsers);
      agoraClient.off('user-left', updateRemoteUsers);
      agoraClient.off('user-published', handleUserPublished);
      agoraClient.off('user-unpublished', handleUserUnpublished);
    };
  }, [agoraClient, setConnectionState, updateRemoteUsers, handleUserPublished, handleUserUnpublished]);

  // Sync sound disabled
  useEffect(() => {
    if (remoteUsers.length === 0) {
      return;
    }

    // Sync the audio tracks of the users with soundDisabled
    for (const user of remoteUsers) {
      if (soundDisabled) {
        user.audioTrack?.stop();
      } else {
        user.audioTrack?.play();
      }
    }

    console.debug('[useVoiceCall] synced soundDisabled', {
      remoteUsers: remoteUsers,
      soundDisabled: soundDisabled,
    });
  }, [remoteUsers, soundDisabled]);

  // Sync mute
  useEffect(() => {
    if (microphoneAudioTrack == null) {
      return;
    }

    // Sync microphone audio track with mute
    microphoneAudioTrack.setVolume(muted ? 0 : 100);

    console.debug('[useVoiceCall] synced mute', {
      microphoneAudioTrack: microphoneAudioTrack,
      muted: muted,
    });
  }, [microphoneAudioTrack, muted]);

  const loading = joining || leaving;

  const voiceCall = useMemo(
    () => ({
      agoraClient: agoraClient,
      connectionState: connectionState,
      remoteUsers: remoteUsers,
      uid: uid ?? initialUID ?? undefined,
      channelName: channelName,
      loading: loading,
      error: error,
      joinAgora: joinAgora,
      joinVoice: joinVoice,
      leave: leave,
    }),
    [
      agoraClient,
      connectionState,
      remoteUsers,
      uid,
      initialUID,
      channelName,
      loading,
      error,
      joinAgora,
      joinVoice,
      leave,
    ]
  );
  return voiceCall;
}
