import type { BoardProps } from 'boardgame.io/react';
import {
  IPlayer,
  GameState,
  BoardPropTypes,
  CtxWithApi,
  BlockGameGameState,
  PiecesForPlayer,
  SHAPE_NAMES,
  Effects,
  Cell,
} from './Types';
import { Game, PhaseConfig, PlayerID } from 'boardgame.io';
import { getAllCellsForPlayer, movesUtil } from './utils/moves.util';
import { assertIsDefined } from './utils/typeUtils';
import { shuffleArray } from './utils/common.util';
import { ActivePlayers } from 'boardgame.io/core';
import { EffectsPlugin } from 'bgio-effects/plugin';
import { EffectsPluginConfig } from 'bgio-effects/dist/types';

export const getGridSize = (players: IPlayer[]) => {
  const GRID_SIZE = 20;
  const GRID_SIZE_2_PLAYER = 14;

  return players.length > 2 ? GRID_SIZE : GRID_SIZE_2_PLAYER;
};

export const getFourCorners = (gridSize: number) => {
  return [
    { x: 0, y: 0 },
    { x: gridSize - 1, y: 0 },
    { x: gridSize - 1, y: gridSize - 1 },
    { x: 0, y: gridSize - 1 },
  ];
};

export const getTwoPlayersStartingPoints = (gridSize: number, grid: number[][]) => {
  const offset = 4;
  const cells = [
    { x: offset, y: offset },
    { x: gridSize - offset - 1, y: gridSize - offset - 1 },
  ];
  return cells.filter((cell) => grid[cell.x][cell.y] === EMPTY_CELL);
};

export const getIsSmall = (players: IPlayer[]) => players.length <= 2;

export const EMPTY_CELL = -1;
export const playerIdToCellValue = (playerId: string | number) => +playerId;

export enum Phases {
  Sync = 'Sync',
  Tutorial = 'Tutorial',
  Playing = 'Playing',
  GameEnd = 'gameEnd',
}

interface SetupData {
  drawingSocketUrl: string;
  players?: IPlayer[];
  extras?: any;
}

export type G = GameState;

export const getInitialBlockGameState = (players: IPlayer[]): BlockGameGameState => {
  const piecesForPlayers = players.reduce<PiecesForPlayer>((acc, player) => {
    acc[player.id] = [...SHAPE_NAMES];
    return acc;
  }, {});
  const grid = Array<Array<number>>(getGridSize(players)).fill(Array(getGridSize(players)).fill(EMPTY_CELL));
  return {
    piecesForPlayer: piecesForPlayers,
    gameGrid: grid,
    activePlayer: shuffleArray(players)[0].id,
    currentlyHoveringPiece: null,
    playersThatSkipped: [],
    lastPlacedPiece: null,
  };
};

type BoardPropsExtended = {
  moves: { [key in keyof typeof movesUtil]: (...arg: any) => void };
  ctx: CtxWithApi;
  playerID: PlayerID;
} & BoardProps<BoardPropTypes>;
export type GameObject = BoardPropsExtended;

let isPlayAgain = false;
const setup = (ctx: CtxWithApi, setupData?: SetupData): GameState => {
  // Weird server bug that only happens first time on local
  if (setupData === undefined) {
    return {} as any;
  }
  isPlayAgain = setupData?.extras?.isPlayAgain ?? false;

  assertIsDefined(setupData.players);
  const initPlayers = setupData.players;
  const players: IPlayer[] = initPlayers.map((player, index) => {
    return {
      id: index.toString(),
      name: player?.name || `Player ${index}`,
      avatarUrl: player?.avatarUrl || `https://i.pravatar.cc/300?img=${Math.random()}`,
      active: true,
      controller_id: player?.controller_id,
      isBot: false,
    };
  });

  return {
    didGameEnd: false,
    isTransition: true,
    phaseStartTime: Date.now(),
    players,
    shouldForceSubmit: false,
    ...getInitialBlockGameState(players),
  };
};

const basePhase = {
  turn: {
    activePlayers: ActivePlayers.ALL,
  },
  moves: {
    timesUp: movesUtil.timesUp,
    forceEndPhase: movesUtil.forceEndPhase,
    transitionTimeUp: movesUtil.transitionTimeUp,
  },
  onEnd: (G: G) => {
    G.isTransition = true;
    G.shouldForceSubmit = false;
    G.phaseStartTime = Date.now();
  },
};

const AllPhases: Record<Phases, PhaseConfig<G, CtxWithApi>> = {
  [Phases.Sync]: {
    ...basePhase,
    moves: {
      ...basePhase.moves,
      endSync: movesUtil.endSync,
    },
    start: true,
    next: Phases.Tutorial,
  },
  [Phases.Tutorial]: {
    ...basePhase,
    next: Phases.Playing,
    moves: {
      ...basePhase.moves,
      endTutorial: movesUtil.endTutorial,
    },
  },
  [Phases.Playing]: {
    ...basePhase,
    next: Phases.GameEnd,
    moves: {
      ...basePhase.moves,
      placePiece: movesUtil.placePiece,
      movePiece: movesUtil.movePiece,
      selectPiece: movesUtil.selectPiece,
      skipTurn: movesUtil.skipTurn,
    },
  },
  [Phases.GameEnd]: {
    ...basePhase,
    moves: {
      ...basePhase.moves,
    },
  },
};

export const GAME_ID = 'magicblocks';

const effectsConfig: EffectsPluginConfig = {
  effects: {
    [Effects.SelectPiece]: {
      duration: 0.1,
      create: (playerId: PlayerID) => ({ playerId }),
    },
    [Effects.PlacePiece]: {
      duration: 0.5,
      create: (playerId: PlayerID) => ({ playerId }),
    },
    [Effects.MirrorPiece]: {
      duration: 0.1,
    },
    [Effects.RotatePiece]: {
      duration: 0.1,
    },
    [Effects.MovePiece]: {
      duration: 0.1,
      create: (direction: Cell) => ({ direction }),
    },
  },
};

export const BlockGame: Game<G, CtxWithApi> = {
  name: GAME_ID,
  setup: setup,
  plugins: [EffectsPlugin(effectsConfig)],
  moves: movesUtil,
  phases: AllPhases,
};

export const getWinner = (G: GameState) =>
  G.players.reduce(
    (acc, p) => {
      const curr = getAllCellsForPlayer(G.gameGrid, playerIdToCellValue(p.id)).length;
      if (acc.score < curr) {
        acc.score = curr;
        acc.playerId = p.id;
      }
      return acc;
    },
    { score: 0, playerId: '0' }
  );
