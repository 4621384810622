import axios from 'axios';

export const URL_SHORTENING_API = 'https://mgy.gg';

const api = axios.create({
  baseURL: URL_SHORTENING_API,
});

interface LengthURLInput {
  short_link: string;
}

interface LengthenURLResult {
  url: string;
}

/** Is the given URL a valid short URL */
export function isShortURL(url: string): boolean {
  try {
    return new URL(url).origin === new URL(URL_SHORTENING_API).origin;
  } catch (e) {
    return false;
  }
}

/** Lengthen given short URL */
export async function lengthenURL(shortURL: string): Promise<string> {
  const slug = new URL(shortURL).pathname.slice(1);
  const params: LengthURLInput = {
    short_link: slug,
  };
  const result = await api.get<LengthenURLResult>('/lengthen', { params: params });
  return `${URL_SHORTENING_API}/${result.data.url}`;
}
