import {
  Communication,
  GameController,
  JoinVoiceResponse,
  YardController,
  YardWithDisplayController,
} from '@magicyard/shared/platform/hooks/usePlatformControllerTypes';
import React, { createContext, useContext, useState } from 'react';
import './MicHeader.css';
import { track } from '@magicyard/shared/src/localAnalytics';

interface MicContextType {
  localMicState: null | 'loading' | JoinVoiceResponse;
  setLocalMicState: React.Dispatch<React.SetStateAction<null | 'loading' | JoinVoiceResponse>>;
}

export const MicContext = createContext<MicContextType>({
  localMicState: null,
  setLocalMicState: () => {},
});

export const MicProvider: React.FC = ({ children }) => {
  const [localMicState, setLocalMicState] = useState<null | 'loading' | JoinVoiceResponse>(null);

  return <MicContext.Provider value={{ localMicState, setLocalMicState }}>{children}</MicContext.Provider>;
};

export const MicHeader = ({
  comms,
  controller,
}: {
  comms: Communication;
  controller: GameController | YardController | YardWithDisplayController;
}) => {
  const { localMicState, setLocalMicState } = useContext(MicContext);
  const micState = comms.voice === null ? null : comms.voice.micState;
  const mySpeakerState = micState?.speakers.find((x) => x.id === controller.profile.id);

  const getOnClickAndText = () => {
    // No voice chat
    if (micState === null) {
      return null;
    }

    if (localMicState === null) {
      return (
        <div
          className={'mic_header-icon_container'}
          onClick={async () => {
            track('Joined voice chat', { voiceActionSource: 'Icons' });
            setLocalMicState('loading');
            setLocalMicState(await micState.join());
          }}
        >
          <div className={'mic_header-icon mic_header-join'} />
        </div>
      );
    }

    if (localMicState === 'loading') {
      return 'loading..';
    }

    if (mySpeakerState === undefined) {
      return (
        <div
          className={'mic_header-icon_container'}
          onClick={() => {
            track('Grab The Mic Clicked');
            localMicState.grab();
          }}
        >
          <div className={'mic_header-icon mic_header-grab'} />
        </div>
      );
    } else {
      return mySpeakerState.muted ? (
        <div className={'mic_header-icon_container'} onClick={() => localMicState.setMute(false)}>
          <div className={'mic_header-icon mic_header-unmute'} />
        </div>
      ) : (
        <div className={'mic_header-icon_container'} onClick={() => localMicState.setMute(true)}>
          <div className={'mic_header-icon mic_header-mute'} />
        </div>
      );
    }
  };

  return (
    <>
      <div className={'mic_header-root'}>
        {getOnClickAndText()}
        {localMicState !== null && localMicState !== 'loading' && (
          <div
            className={'mic_header-icon_container'}
            onClick={async () => {
              setLocalMicState('loading');
              await localMicState.leave();
              setLocalMicState(null);
            }}
          >
            <div className={'mic_header-icon mic_header-leave'} />
          </div>
        )}
      </div>
    </>
  );
};
