import React, { useEffect } from 'react';
import GameManager from '../GameManager/GameManager';
import GameContext from '../store/GameContext';

import { GameObject } from '@magicyard/magicblocks-game/src/Game';
import { updateAnalyticsData } from '@magicyard/shared/src/localAnalytics';

const Board = (props: { G: GameObject; onStartNewGame: () => void }) => {
  const { matchID } = props.G;

  useEffect(() => {
    updateAnalyticsData({ matchId: matchID });
  }, [matchID]);
  return (
    <GameContext.Provider value={props.G}>
      <GameManager onStartNewGame={props.onStartNewGame} />
    </GameContext.Provider>
  );
};

export default Board;
