import { isIOS, useFullscreen } from '@magicyard/shared/platform/hooks/useTryFullscreen';
import React from 'react';

export const ToggleFullScreenButton = () => {
  const { toggleFullScreen } = useFullscreen();
  if (isIOS()) {
    return null;
  }

  return (
    <button
      style={{
        userSelect: 'none',
        zIndex: 1111,
        position: 'absolute',
        top: 0,
        left: 0,
        border: 'none',
        background: 'transparent',
      }}
      onClick={toggleFullScreen}
    >
      fullscreen
    </button>
  );
};
