import { Local, SocketIO } from 'boardgame.io/multiplayer';

export const resolveURL = (envVar: string | undefined, required: boolean = false): string | undefined => {
  if (required && envVar === undefined) {
    throw new Error(`Missing required env var: ${envVar}`);
  }
  return envVar === undefined || envVar === '' || envVar.startsWith('http')
    ? envVar
    : new URL(envVar, window.origin).href;
};

export const PLATFORM_SERVER_URL = resolveURL(process.env.REACT_APP_PLATFORM_SERVER_URL, true);
export const PLATFORM_CONTROLLER_URL = resolveURL(process.env.REACT_APP_PLATFORM_CONTROLLER_URL);
export const PLATFORM_DISPLAY_URL = resolveURL(process.env.REACT_APP_PLATFORM_DISPLAY_URL);
export const GAME_START_SERVER_URL = resolveURL(process.env.REACT_APP_GAME_START_SERVER_URL);
export const GAME_START_CONTROLLER_URL = resolveURL(process.env.REACT_APP_GAME_START_CONTROLLER_URL);
export const GAME_START_DISPLAY_URL = resolveURL(process.env.REACT_APP_GAME_START_DISPLAY_URL);

export const CONFIG = {
  PLATFORM_SERVER_URL,
  PLATFORM_CONTROLLER_URL,
  PLATFORM_DISPLAY_URL,
  GAME_START_SERVER_URL,
  GAME_START_CONTROLLER_URL,
  GAME_START_DISPLAY_URL,
  MGY_ENV: process.env.REACT_APP_MGY_ENV,
  MIXPANEL_API_KEY: process.env.REACT_APP_MIXPANEL_API_KEY,
};

// TODO this should be handled better

let socket: null | ReturnType<typeof SocketIO> = null;
let lastServerUrl: string | null = null;
export const getMultiplayerMode = (serverURL?: string): ReturnType<typeof SocketIO> | ReturnType<typeof Local> => {
  const urlParams = new URLSearchParams(window.location.search);
  const serverURLOverride = urlParams.get('serverURL');
  const localMultiplayer = urlParams.get('local');

  if (localMultiplayer) {
    console.log('Using Local() multiplayer');
    return Local();
  }

  const serverURLFinal = serverURLOverride ?? serverURL;

  if (serverURLFinal !== lastServerUrl || socket === null) {
    socket = SocketIO({ server: serverURLFinal });
  }

  console.log(`Running using SocketIO() multiplayer with server: ${serverURLFinal}`);
  return socket;
};

export const getControllerUrlConnectDisplay = (displayId: string) =>
  `${PLATFORM_CONTROLLER_URL}?displayId=${displayId}`;

// TODO: invite link should be universal (Openable on display and not coupled to controller)
export const getYardInviteLink = (yardId: string) => `${PLATFORM_CONTROLLER_URL}?yardId=${yardId}`;

export const objectKeys = <T extends Object>(obj: T): Array<keyof T> => {
  return Object.keys(obj) as Array<keyof T>;
};
