import React, { useMemo } from 'react';
import { useGameObject } from '../store/GameContext';
import { Phases } from '@magicyard/magicblocks-game/src/Game';
import Background from '../components/Background/Background';
import { LoaderTemplate } from '../components/loader-template/LoaderTemplate';
import { Playing } from './Stages/Playing';
import { GameOver } from './Stages/GameOver';
import { Tutorial } from './Stages/Tutorial';

const LookAtScreen = () => {
  return <LoaderTemplate />;
};

const StageManager = (props: { onStartNewGame: () => void }) => {
  const { ctx } = useGameObject();

  const stages: Record<Phases, () => JSX.Element> = useMemo(
    () => ({
      [Phases.Sync]: LookAtScreen,
      [Phases.Tutorial]: Tutorial,
      [Phases.Playing]: Playing,
      [Phases.GameEnd]: () => <GameOver onStartNewGame={props.onStartNewGame} />,
    }),
    []
  );
  const phase = ctx.phase as Phases;
  const Stage = stages[phase];
  return (
    <Background>
      <Stage />
    </Background>
  );
};

export default StageManager;
