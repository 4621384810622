import React from 'react';

import './NavigationBar.css';

export type NavigationBarProps = {
  header?: string;
};
export const NavigationBar = (props: NavigationBarProps) => {
  const { header } = props;
  return (
    <div className={'navigation-bar_root'}>
      <div className={'navigation-bar_content'}>
        <div className={'navigation-bar_header'}>{header}</div>
        {header === undefined || header.length === 0 ? <div style={{ height: 32 }} /> : undefined}
        {/*<div>*/}
        {/*  <BurgerIconSvg key={'burger-icon'} />;*/}
        {/*</div>*/}
      </div>
    </div>
  );
};
