import { ActivePlayersArg, Ctx, PlayerID } from 'boardgame.io';
import { G } from './Game';
import { RandomAPI } from 'boardgame.io/dist/types/src/plugins/random/random';
import { EventsAPI } from 'boardgame.io/dist/types/src/plugins/events/events';
import { movesUtil } from './utils/moves.util';
import { assertIsDefined } from './utils/typeUtils';

export interface EventsAPIGuaranteed extends EventsAPI {
  endGame(gameover?: any): void;

  endPhase(): void;

  endStage(): void;

  endTurn(arg?: { next: PlayerID }): void;

  pass(arg?: { remove: true }): void;

  setActivePlayers(arg: ActivePlayersArg): void;

  setPhase(newPhase: string): void;

  setStage(newStage: string): void;
}
export enum Effects {
  PlacePiece = 'placePiece',
  RotatePiece = 'rotatePiece',
  MovePiece = 'movePiece',
  MirrorPiece = 'mirrorPiece',
  SelectPiece = 'selectPiece',
}

export interface CtxWithApi extends Ctx {
  events: EventsAPIGuaranteed;
  random: RandomAPI;
  playerID: PlayerID; // Assuming playerId is always present maybe wrong
  effects: Record<Effects, (...args: any) => void>;
}

export type BoardPropTypes = {
  ctx: CtxWithApi;
  events: unknown;
  moves: { [key in keyof typeof movesUtil]: (...arg: any) => void };
  playerID: PlayerID;
} & G;

export interface IPlayer {
  id: PlayerID;
  name: string;
  avatarUrl: string;
  controller_id: string;
  isBot: boolean;
}

export type Answer = { answer: string; timeTaken: number; promptIndex: number };

export interface DefaultGameState {
  didGameEnd: boolean;
  isTransition: boolean;
  phaseStartTime: number;
  players: IPlayer[];
  shouldForceSubmit: boolean;
}

export interface BlockGameGameState {
  currentlyHoveringPiece: CurrentlyHoveringPiece | null;
  playersThatSkipped: PlayerID[];
  lastPlacedPiece: { cells: Cell[]; id: string; placedBy: PlayerID } | null;
  gameGrid: Array<Array<number>>;
  piecesForPlayer: PiecesForPlayer;
  activePlayer: PlayerID;
}

export type CurrentlyHoveringPiece = {
  indexInHand: number;
  shape: ShapeName;
  rotation: number;
  centerLocation: Cell;
  mirrored: boolean;
};

export type PiecesForPlayer = Record<PlayerID, ShapeName[]>;

export type ShapeStructure = Array<Array<number>>;
export const SHAPE_NAME_TO_SHAPE: Record<ShapeName, ShapeStructure> = {
  '.': [[1]],
  '..': [
    [1, 1],
    [0, 0],
  ],
  ',': [
    [0, 0, 1],
    [0, 1, 1],
    [0, 0, 0],
  ],
  '...': [
    [0, 0, 0],
    [1, 1, 1],
    [0, 0, 0],
  ],
  '....': [
    [0, 0, 0, 0],
    [1, 1, 1, 1],
    [0, 0, 0, 0],
    [0, 0, 0, 0],
  ],
  j: [
    [1, 1, 1],
    [0, 0, 1],
    [0, 0, 0],
  ],
  O: [
    [1, 1],
    [1, 1],
  ],
  z: [
    [0, 1, 1],
    [1, 1, 0],
    [0, 0, 0],
  ],
  t: [
    [0, 1, 0],
    [1, 1, 1],
    [0, 0, 0],
  ],
  J: [
    [0, 0, 0, 0],
    [1, 1, 1, 1],
    [0, 0, 0, 1],
    [0, 0, 0, 0],
  ],

  Z: [
    [0, 1, 1],
    [0, 1, 0],
    [1, 1, 0],
  ],
  T: [
    [0, 0, 1],
    [1, 1, 1],
    [0, 0, 1],
  ],
  '.....': [
    [0, 0, 0, 0, 0],
    [0, 0, 0, 0, 0],
    [1, 1, 1, 1, 1],
    [0, 0, 0, 0, 0],
    [0, 0, 0, 0, 0],
  ],
  d: [
    [0, 0, 1],
    [0, 1, 1],
    [0, 1, 1],
  ],
  W: [
    [0, 1, 1],
    [1, 1, 0],
    [1, 0, 0],
  ],
  C: [
    [1, 1, 0],
    [1, 0, 0],
    [1, 1, 0],
  ],
  '+': [
    [0, 1, 0],
    [1, 1, 1],
    [0, 1, 0],
  ],
  V: [
    [0, 0, 1],
    [0, 0, 1],
    [1, 1, 1],
  ],
  '~': [
    [0, 0, 1, 0],
    [0, 0, 1, 0],
    [0, 1, 1, 0],
    [0, 1, 0, 0],
  ],
  '/': [
    [0, 1, 0],
    [0, 1, 1],
    [1, 1, 0],
  ],
  '_.': [
    [0, 0, 0, 0],
    [0, 1, 0, 0],
    [1, 1, 1, 1],
    [0, 0, 0, 0],
  ],
};

export const SHAPE_NAME_TO_SIZE: Record<ShapeName, number> = {
  '.': 1,
  '..': 2,
  ',': 3,
  '...': 3,
  '....': 4,
  j: 4,
  O: 4,
  z: 4,
  t: 4,
  J: 5,
  Z: 5,
  T: 5,
  '.....': 5,
  d: 5,
  W: 5,
  C: 5,
  '+': 5,
  V: 5,
  '~': 5,
  '/': 5,
  '_.': 5,
};

export const SHAPE_NAME_TO_HAS_MIRROR: Record<ShapeName, boolean> = {
  '.': false,
  '..': false,
  ',': false,
  '...': false,
  '....': false,
  O: false,
  t: false,
  T: false,
  '.....': false,
  W: false,
  C: false,
  '+': false,
  V: false,

  j: true,
  z: true,
  J: true,
  Z: true,
  d: true,
  '~': true,
  '/': true,
  '_.': true,
};

export type ShapeName = (typeof SHAPE_NAMES)[number];
export const SHAPE_NAMES = [
  '.' as const,
  '..' as const,
  ',' as const,
  '...' as const,
  '....' as const,
  'j' as const,
  'O' as const,
  'z' as const,
  't' as const,
  'J' as const,
  'Z' as const,
  'T' as const,
  '+' as const,
  '.....' as const,
  'd' as const,
  'W' as const,
  'C' as const,
  'V' as const,
  '~' as const,
  '/' as const,
  '_.',
];

export type Cell = { x: number; y: number };

export type GameState = DefaultGameState & BlockGameGameState;

export const valueToColor: Record<string, string> = {
  '-1': '#eaeaea',
  '0': '#fbc117',
  '1': '#005a5a',
  '2': '#f85f00',
  '3': '#e40001',
};
export const valueToSecondaryColor: Record<string, string> = {
  '0': '#968862',
  '1': '#789595',
  '2': '#b37148',
  '3': '#b58585',
};
export const valueToHighlightColor: Record<string, string> = {
  '-1': '#f8f8f8',
  '0': '#e3cc88',
  '1': '#94c4c4',
  '2': '#f99a60',
  '3': '#fa8d8e',
};

export const valueToBrightness: Record<string, string> = {
  '0': '0.96',
  '1': '0.85',
  '2': '0.95',
  '3': '0.90',
};

export const valueToXColor: Record<string, string> = {
  '0': 'black',
  '1': 'black',
  '2': 'black',
  '3': 'black',
};

function hexToRgb(hex: string) {
  // Expand shorthand form (e.g. "03F") to full form (e.g. "0033FF")
  const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
  hex = hex.replace(shorthandRegex, function (m, r, g, b) {
    return r + r + g + g + b + b;
  });

  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result
    ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16),
      }
    : null;
}

export function hexToRgba(hex: string, a: number) {
  const rgb = hexToRgb(hex);
  assertIsDefined(rgb);
  return `rgba(${rgb.r},${rgb.g},${rgb.b},${a})`;
}
