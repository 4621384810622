import React from 'react';
import RatShadeImage from '../../assets/loaders/RatShadeImage.webp';
import './LoaderTemplate.css';

interface LoaderTemplateProps {
  image?: string;
  title?: string;
}

export const LoaderTemplate = (props: LoaderTemplateProps) => {
  return <div className={'loader-template_root'}>{props.title ?? 'Look at the tv'}</div>;
};
